import React, {useEffect} from "react";
import {ticketTypes} from '.././constants/TicketTypes';
import {arrBlocks} from '.././constants/Blocks';
import moment from "moment";



function CheckOutReport({data, selectedDate}) {

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        printCheckOutReport()
      }, []);

    const printCheckOutReport = () => {
        var printWindow = window.open(' ', 'Check Outs Report', 'height=600,width=800');
        printWindow.document.write('<html><head>');
        printWindow.document.write('</head><body style="font-family:verdana; font-size:14px;width:100%;">');
        printWindow.document.write(document.getElementById("divCheckOutReport").innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();  

      }

      const getGuestTicketTypes = (ticketTypeIds) => {

        var arrTicketTypes = ticketTypeIds.split(", ");
        var arrInsertedTicketTypes = [];

        if (arrTicketTypes.length > 1){
            for (let i = 0; i < arrTicketTypes.length; i++){
                arrInsertedTicketTypes.push(ticketTypes[arrTicketTypes[i]]);
            } 
        } else {
            arrInsertedTicketTypes.push(ticketTypes[ticketTypeIds]);
        }
        return arrInsertedTicketTypes;
      }

  return (
    <>
      <div className="wrapper">
            <div id="divCheckOutReport" style={{visibility:'hidden', display:'none'}}>
            <h4 style={{margin: 0, textAlign: 'center'}}>IYF {currentYear}</h4>
                        <h5 style={{margin: 0, textAlign: 'center'}}>PARMARTH NIKETAN, RISHIKESH</h5>
                        <h6 style={{textAlign: 'center'}}>{selectedDate} - Check Outs Report</h6>

                        <table style={{borderSpacing: 0,borderCollapse: 'collapse', position: 'absolute', width: '100%', fontSize: 12}}>
                        <tbody>
                            <tr>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '1%'}}>#</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '10%'}}>Guest Name</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Ticket Types</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Room No.</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Check Out Time</td>
                            </tr>
                            {data.map(item => (
                            <tr>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {data.findIndex(x => x.guest_name === item.guest_name) + 1}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.guest_name}
                                </td>

                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black', whiteSpace: 'nowrap'}}>
                                        {(getGuestTicketTypes(item.ticket_type_ids)).map((tType) => (
                                                <div> {tType}  </div>
                                                ))} 
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {arrBlocks[item.block_id-1]} - {item.room_no}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {moment(item.actual_departure_date).format("YYYY-MM-DD hh:mm a")}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
            </div>
      </div>
    </>
  );
}

export default CheckOutReport;
