import React, { useState, useEffect } from 'react';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import {logError, checkError} from './utils/helpers';

// reactstrap components
import {  Modal, ModalBody, Container } from "reactstrap";

// core components
import Header from "./Header";
import isOnline from 'is-online';

import { useNavigate } from "react-router-dom";

import CheckOutDetails from './CheckOutDetails';

var arrDataSource = [];

const CheckOuts = () => {

  const navigate = useNavigate();

  const [showCheckOutDetails, setCheckOutDetailsFlag] = useState(false);
  const [guestIdDetailId, setGuestIdDetailId] = useState(0);
 
  const handleOnSelect = (item) => {

    setGuestIdDetailId(item.id);
    setCheckOutDetailsFlag(true);

  }

  const handleOnClear = (item) => {
    setCheckOutDetailsFlag(false);
  }
  
  const formatResult = (item) => {
    return (
      <div style={{ display: 'block' }}>
          <span className="result-span">{item.name}</span>
          <span className="result-type-span">{item.type_name} </span>
      </div>
    )
  }

  const [modalCO, setModalCO] = React.useState(false);

  const loadData = () => {

      if (sessionStorage.getItem('authenticated') === 'true'){
          //get guest names and ticket types for search
          var totalCount = 0;
          return fetch("/apiGuest/1")
            .then((response) => {
              totalCount = response.headers.get('X-Total-Count');
              return response.json()
            })
            .then((result) => {
              for (let i = 0; i < parseInt(totalCount); ++i) {
                arrDataSource.push({ 
                  id: result[i].guest_id_detail_id, 
                  name: result[i].guest_name, 
                  type_name: result[i].type_name
                });
              }
              setDataSource(arrDataSource);
              return arrDataSource;
            })
        } else {
          navigate("/", { replace: true });
        }
  }
  
  const [dataSource, setDataSource] = useState(loadData);

  useEffect(() => {
    return () => {
      arrDataSource = [];
    };
  }, []);
  

  if (sessionStorage.getItem("has_checkout_checklist_view") === 'false' || sessionStorage.getItem("has_checkout_checklist_view") === '0'){
    return (
      <>
      <Header selected="Check Out"/>
      <div class="divErrorMsg">Sorry, no access!</div>);
      </> 
  );
} else {

  return (
    <>

      <Modal
        modalClassName="modal-mini modal-danger"
        toggle={() => setModalCO(false)}
        isOpen={modalCO}>
        <div className="modal-header justify-content-center">
          No internet!
        </div>
        <ModalBody>
          <p>Oops, you are offline! Your data might not have saved.</p>
        </ModalBody>
      </Modal>

      <Header selected="Check Out"/>
      <div className="wrapper">
      
        <div className="section section-about-us">
          <Container>
                <h2 className="title">Check Outs</h2>
                <div className="Dashboard">
                                    <header className="Dashboard-header">
                                      <div style={{ width: 400 }}>
                                        <ReactSearchAutocomplete
                                          items={dataSource}
                                          onSelect={handleOnSelect}
                                          onClear={handleOnClear}
                                          autoFocus
                                          formatResult={formatResult}
                                          maxResults={5}
                                          placeholder = "Type the guest name here..."
                                        />
                                      </div>
                                    </header>
                                </div>

                               { showCheckOutDetails ? (
                                   <CheckOutDetails gId={guestIdDetailId} /> 
                                  ): null
                                }
          </Container>
        </div>
      </div>
    </>
  );
}
}

export default CheckOuts;
