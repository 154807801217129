import React, { useState } from "react";
import {ticketTypes} from './constants/TicketTypes';
import Datetime from "react-datetime";
import moment from "moment";

// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import Header from "./Header";
import TicketTypes from "./reports/TicketTypes";
import FoodCounts from './reports/FoodCounts';  
import DayPasses from './reports/DayPasses';
import ExpectedArrivals from './reports/ExpectedArrivals';
import ExpectedDepartures from './reports/ExpectedDepartures';
import CheckInReport from './reports/CheckInReport';
import CheckOutReport from './reports/CheckOutReport';
import RoomOccupancy from './reports/RoomOccupancy';
import CountryAmbassadors from './reports/CountryAmbassadors';
import PresentersReport from './reports/PresentersReport';
import ReferenceGuestsReport from './reports/ReferenceGuestsReport';  

import { useNavigate } from "react-router-dom";

var arrTicketTypeAttendees = [];
var arrFoodCounts = []; 
var arrDayPasses = [];
var arrExpectedArrivals = [];
var arrExpectedDepartures = [];
var arrCheckIns = [];
var arrCheckOuts = [];
var arrRoomOccupancy = [];
var arrCountryAmbassadors = [];
var arrPresenters = []; 
var arrReferenceGuests = [];


const populateTicketTypes = () => {

  const excludeTicketTypes = [1,2,4,6,7,10,11,12,15,17,19];

  let items = [];   

  //update total ticket count here in case ticket types are added/removed 

  for (let i = 1; i <= 37; i++) {    
    if (!(excludeTicketTypes.includes(i))){
      items.push(<option key={i} value={i}>{ticketTypes[i]}</option>);  
    }        
  }
  return items;
}

function Reports() {

  const navigate = useNavigate();

  const [showTicketTypes, setTicketTypeFlag] = useState(false);
  const [showFoodCounts , setFoodCountsFlag] = useState(false); 
  const [showDayPasses, setDayPassFlag] = useState(false);
  const [showExpectedArrivals, setExpectedArrivalsFlag] = useState(false);
  const [showExpectedDepartures, setExpectedDeparturesFlag] = useState(false);
  const [showCheckIns, setCheckInsFlag] = useState(false);
  const [showCheckOuts, setCheckOutsFlag] = useState(false);
  const [showRoomOccupancy, setRoomOccupancyFlag] = useState(false);
  const [showCountryAmbassadors, setCountryAmbassadorsFlag] = useState(false);
  const [showPresenters, setPresentersFlag] = useState(false);  
  const [showReferenceGuests, setReferenceGuestsFlag] = useState(false);


  const downloadTicketTypes = () => {

    return fetch("/apiReports/1?tTId=" + document.getElementById("slTicketTypes").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrTicketTypeAttendees = result;
        setTicketTypeFlag(true);
      })
  }

  const downloadFoodCounts = () => {
    return fetch("/apiReports/9?food=" + document.getElementById("dtFoodCounts").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrFoodCounts = result;
        setFoodCountsFlag(true);
      })
  }

  const downloadPresenters = () => {
    return fetch("/apiReports/10")
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrPresenters = result;
        setPresentersFlag(true);
      })
  }

  const downloadReferenceGuests = () => {
    return fetch("/apiReports/11")
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrReferenceGuests = result;
        setReferenceGuestsFlag(true);
      })
  }


  const downloadDayPasses = () => {

    return fetch("/apiReports/2?daypass=" + document.getElementById("dtDayPasses").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrDayPasses = result;
        setDayPassFlag(true);
      })
  }

  const downloadExpectedArrivals = () => {
    return fetch("/apiReports/3?exparr=" + document.getElementById("dtExpectedArrivals").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrExpectedArrivals = result;
        setExpectedArrivalsFlag(true);
      })
  }

  const downloadExpectedDepartures = () => {
    return fetch("/apiReports/4?expdep=" + document.getElementById("dtExpectedDepartures").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrExpectedDepartures = result;
        setExpectedDeparturesFlag(true);
      })
  }

  const downloadCheckIns = () => {
    return fetch("/apiReports/5?actarr=" + document.getElementById("dtCheckIns").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrCheckIns = result;
        setCheckInsFlag(true);
      })
  }

  const downloadCheckOuts = () => {
    return fetch("/apiReports/6?actdep=" + document.getElementById("dtCheckOuts").value)
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrCheckOuts = result;
        setCheckOutsFlag(true);
      })
  }

  const downloadRoomOccupancy = () => {
    return fetch("/apiReports/7")
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrRoomOccupancy = result;
        setRoomOccupancyFlag(true);
      })
  }

  const downloadCountryAmbassadors = () => {
    return fetch("/apiReports/8")
      .then((response) => {
         return response.json()
      })
      .then((result) => {
        arrCountryAmbassadors = result;
        setCountryAmbassadorsFlag(true);
      })
  }

  function valid(current) {
    var sDate = moment("2024-03-01", "YYYY-MM-DD");
    var eDate = moment("2024-03-21", "YYYY-MM-DD");

    return (current.isAfter(sDate) && current.isBefore(eDate));
  }

    if (sessionStorage.getItem('authenticated') === 'false') {
      navigate("/", { replace: true });
    } else {
          return (
            <>
              <Header selected="Reports"/>
              <div className="wrapper">
                <div className="section section-about-us">
                  <Container>
                  <h2 className="title">Reports</h2>
                    <Row>
                        <Col className="text-center" md="6">
                        <h5 className="description report">
                                <Card style={{padding:'0 0.4em 0 0.4em'}}>
                                <CardHeader>
                                    Ticket Type Attendees
                                </CardHeader>
                                <CardBody>
                                    <TabContent className="text-center">
                                        <p> 
                                        <select id="slTicketTypes" onChange={() => setTicketTypeFlag(false)} style={{marginBottom:'1em'}} className="form-control">
                                            <option value="0">Please select</option>
                                            {populateTicketTypes()}                   
                                        </select>    
                                        <Button color="info" type="button" onClick={downloadTicketTypes}>
                                          Download
                                        </Button>  
                                        {
                                            showTicketTypes ? (
                                              <TicketTypes data={arrTicketTypeAttendees} ticketTypeName = {ticketTypes[document.getElementById("slTicketTypes").value]} /> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                          <h5 className="description report">
                              <Card className="card-report">
                              <CardHeader>
                                  Food Counts
                              </CardHeader>
                              <CardBody>
                                  <TabContent className="text-center">
                                      <p> 
                                      <Datetime
                                      inputProps={{ id: 'dtFoodCounts', placeholder: "Select Date", style: {marginBottom: '1em'}}}
                                      dateFormat="YYYY-MM-DD"
                                      timeFormat={false}
                                      closeOnSelect={true}  
                                      onChange={() => setFoodCountsFlag(false)}
                                      isValidDate = {valid}                    
                                    />
                                      <Button color="info" type="button" onClick={downloadFoodCounts}>
                                        Download
                                      </Button>  
                                      {
                                          showFoodCounts ? (
                                            <FoodCounts data={arrFoodCounts} selectedDate = {document.getElementById("dtFoodCounts").value} /> 
                                          ): null
                                      } 
                                      </p>
                                  </TabContent>
                              </CardBody>
                              </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                        <h5 className="description report">
                              <Card className="card-report">
                              <CardHeader>
                                  Day Passes
                              </CardHeader>
                              <CardBody>
                                  <TabContent className="text-center">
                                      <p> 
                                      <Datetime
                                      inputProps={{ id: 'dtDayPasses', placeholder: "Select Date", style: {marginBottom: '1em'}}}
                                      dateFormat="YYYY-MM-DD"
                                      timeFormat={false}
                                      closeOnSelect={true}  
                                      onChange={() => setDayPassFlag(false)}
                                      isValidDate = {valid}                    
                                    />
                                      <Button color="info" type="button" onClick={downloadDayPasses}>
                                        Download
                                      </Button>  
                                      {
                                          showDayPasses ? (
                                            <DayPasses data={arrDayPasses} selectedDate = {document.getElementById("dtDayPasses").value} /> 
                                          ): null
                                      } 
                                      </p>
                                  </TabContent>
                              </CardBody>
                              </Card>
                            </h5> 
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" md="3">
                            <h5 className="description report">
                                <Card className="card-report">
                                <CardHeader>
                                    Expected Arrivals
                                </CardHeader>
                                <CardBody>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Datetime
                                        inputProps={{ id: 'dtExpectedArrivals', placeholder: "Select Date", style: {marginBottom: '1em'}}}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}  
                                        onChange={() => setExpectedArrivalsFlag(false)}                     
                                      />
                                        <Button color="info" type="button" onClick={() => downloadExpectedArrivals()}>
                                          Download
                                        </Button>  
                                        {
                                            showExpectedArrivals ? (
                                              <ExpectedArrivals data={arrExpectedArrivals} selectedDate = {document.getElementById("dtExpectedArrivals").value} /> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                            <h5 className="description report">
                                <Card className="card-report">
                                <CardHeader>
                                    Expected Departures
                                </CardHeader>
                                <CardBody>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Datetime
                                        inputProps={{ id: 'dtExpectedDepartures', placeholder: "Select Date", style: {marginBottom: '1em'}}}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}     
                                        onChange={() => setExpectedDeparturesFlag(false)}                
                                      />
                                        <Button color="info" type="button" onClick={() => downloadExpectedDepartures()}>
                                          Download
                                        </Button>  
                                        {
                                            showExpectedDepartures ? (
                                              <ExpectedDepartures data={arrExpectedDepartures} selectedDate = {document.getElementById("dtExpectedDepartures").value} /> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                            <h5 className="description report">
                                <Card className="card-report">
                                <CardHeader>
                                    Check Ins
                                </CardHeader>
                                <CardBody>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Datetime
                                        inputProps={{ id: 'dtCheckIns', placeholder: "Select Date", style: {marginBottom: '1em'}}}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}     
                                        onChange={() => setCheckInsFlag(false)}                  
                                      />
                                        <Button color="info" type="button" onClick={() => downloadCheckIns()}>
                                          Download
                                        </Button>  
                                        {
                                            showCheckIns ? (
                                              <CheckInReport data={arrCheckIns} selectedDate = {document.getElementById("dtCheckIns").value} /> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                            <h5 className="description report">
                                <Card className="card-report">
                                <CardHeader>
                                    Check Outs
                                </CardHeader>
                                <CardBody>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Datetime
                                        inputProps={{ id: 'dtCheckOuts', placeholder: "Select Date", style: {marginBottom: '1em'}}}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        closeOnSelect={true}      
                                        onChange={() => setCheckOutsFlag(false)}                
                                      />
                                        <Button color="info" type="button" onClick={() => downloadCheckOuts()}>
                                          Download
                                        </Button>  
                                        {
                                            showCheckOuts ? (
                                              <CheckOutReport data={arrCheckOuts} selectedDate = {document.getElementById("dtCheckOuts").value} /> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                      <Col className="text-center" md="3">
                              <h5 className="description report">
                                <Card className="card-report">
                                <CardHeader>
                                    Presenters
                                </CardHeader>
                                <CardBody style={{padding: '0 2.5em 0 2.5em'}}>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Button color="info" type="button" onClick={() => downloadPresenters()}>
                                          Download
                                        </Button>  
                                        {
                                            showPresenters ? (
                                              <PresentersReport data={arrPresenters}/> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                              </h5> 
                          </Col>

                        <Col className="text-center" md="3">
                            <h5 className="description report">
                              <Card className="card-report">
                              <CardHeader>
                                  Guest Room Occupancy
                              </CardHeader>
                              <CardBody>
                                  <TabContent className="text-center">
                                      <p> 
                                      <Button color="info" type="button" onClick={() => downloadRoomOccupancy()}>
                                        Download
                                      </Button>  
                                      {
                                          showRoomOccupancy ? (
                                            <RoomOccupancy data={arrRoomOccupancy}/> 
                                          ): null
                                      } 
                                      </p>
                                  </TabContent>
                              </CardBody>
                              </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                            <h5 className="description report">
                                <Card className="card-report">
                                <CardHeader>
                                    Country Ambassadors
                                </CardHeader>
                                <CardBody>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Button color="info" type="button" onClick={() => downloadCountryAmbassadors()}>
                                          Download
                                        </Button>  
                                        {
                                            showCountryAmbassadors ? (
                                              <CountryAmbassadors data={arrCountryAmbassadors}/> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5> 
                        </Col>
                        <Col className="text-center" md="3">
                            <h5 className="description report">
                            <Card className="card-report">
                                <CardHeader>
                                    Reference Guests
                                </CardHeader>
                                <CardBody style={{padding: '0 2.5em 0 2.3em'}}>
                                    <TabContent className="text-center">
                                        <p> 
                                        <Button color="info" type="button" onClick={() => downloadReferenceGuests()}>
                                          Download
                                        </Button>  
                                        {
                                            showReferenceGuests ? (
                                              <ReferenceGuestsReport data={arrReferenceGuests}/> 
                                            ): null
                                        } 
                                        </p>
                                    </TabContent>
                                </CardBody>
                                </Card>
                            </h5> 
                        </Col>
                        
                    </Row>           
                  </Container>
                </div>
              </div>
            </>
          );
    }
}

export default Reports;
