import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {Blocks} from './constants/Blocks';
import {ticketTypes} from './constants/TicketTypes';

function CheckOutDetails({gId}) {

  const amountValues = {
      1: '$10',
      2: '€10', 
      3: '£10', 
      4: '₹1000'
  };

  const populateAmount = () => {

    let items = [];
    items = Object.keys(amountValues).map((key) => (
      <option key={key} value={key}>{amountValues[key]}</option>
    ));

    return items;
  }

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();
    const [guestIdDetailId, setGuestIdDetailId] = useState();

    const [details, setDetails] = useState({
      GuestName: "", 
      TicketTypeIds: [], 
      BlockId: "",
      RoomNo: "",
      CurrencyId: "",
      ReceiptNo: "",
      RoomReceiptReturned: false,
      RoomKeyReturned: false,
      RoomKeyLost: false  
    });

    useEffect(() => { 
      setGuestIdDetailId(gId);
      getGuestDetails(gId);
    }, [gId]);

    const getGuestDetails = async (guestIdDetailId) => {
      return fetch("/apiCheckOut/" + guestIdDetailId)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
            var data = result[0];
            
            setDetails({  
              GuestName : data.guest_name,    
              TicketTypeIds: data.ticket_type_ids.split(", "),             
              BlockId: data.block_id,
              RoomNo: data.room_no,
              CurrencyId: data.currency_id,
              ReceiptNo: data.receipt_no,
              RoomReceiptReturned: data.room_receipt_returned,
              RoomKeyReturned: data.room_key_returned,
              RoomKeyLost: data.room_key_lost
            });
      })
    }


    const populateBlocks = () => {
      let items = [];
      items = Object.keys(Blocks).map((key) => (
        <option key={key} value={key}>{Blocks[key]}</option>
      ));

      return items;
    }

    const updateCheckOut = async (e) => {

      e.preventDefault();
      
        const payload = {
          block_id: details.BlockId,
          room_no: details.RoomNo.trim(),
          currency_id: details.CurrencyId,
          receipt_no: details.ReceiptNo.trim(),
          room_receipt_returned: details.RoomReceiptReturned,
          room_key_returned: details.RoomKeyReturned,
          room_key_lost: details.RoomKeyLost
        };
      
      const response = await store("/apiCheckOut/" + guestIdDetailId, JSON.stringify(payload))
      .then((response) => {
        if (response.status === 200) {
          setAlertMsg(details.GuestName + " saved successfully.");
          setAlertColor("success");
          setShowAlert(true);

          setTimeout(() => {
            document.getElementById("frmCheckOut").submit();
          }, 2000); // Delay of 3 seconds

        } else {
          setAlertMsg("Oops, there has been issue. Please try again.")
          setAlertColor("danger");
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setAlertMsg(error.response.data.message)
        setAlertColor("danger");
        setShowAlert(true);
      });
    }
      
  return (
          <div className="wrapper">
              <div id="divCheckOutDetails" className="divDetails">
                    <Col className="text-left" md="10">
                        <h5 className="description">
                              <Card>
                                    <CardHeader>{details.GuestName}</CardHeader>
                                    <CardBody>
                                    {details.TicketTypeIds.map((guestTicketType, index) => (
                                            <div className="divTicketTypes" key={index}>{ticketTypes[guestTicketType]}</div>
                                        ))}
                                            <TabContent className="text-left">                                       
                                            <Form id="frmCheckOut" onSubmit={(e) => updateCheckOut(e)}>
                                            <Alert isOpen={showAlert} color={alertColor}>
                                              {alertMsg}
                                            </Alert>
                                                  <Row>
                                                        <Col md={3}>
                                                              <FormGroup>
                                                                <Label for="slBlocks" className="form-label">
                                                                  Block:
                                                                </Label>
                                                                <Input type="select" required id="slBlocks" className="form-control" value={details.BlockId} onChange={(e) => setDetails({ ...details, BlockId: e.target.value })} >
                                                                <option key={0} value={""}>Please select one</option>
                                                                  {populateBlocks()}
                                                                </Input>
                                                              </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                              <FormGroup>
                                                                <Label for="txtRoomNo" className="form-label">
                                                                  Room No:
                                                                </Label>
                                                                <Input required type="text" id="txtRoomNo" value={details.RoomNo} onChange={(e) => setDetails({ ...details, RoomNo: e.target.value })} />
                                                              </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                              <FormGroup> 
                                                                <Label for="slAmount" className="form-label">
                                                                    Amount:
                                                                    </Label>
                                                                    <Input type="select" required id="slAmount" className="form-control" value={details.CurrencyId} onChange={(e) => setDetails({ ...details, CurrencyId: e.target.value })}>
                                                                    <option key={0} value={""}>Please select one</option>
                                                                    {populateAmount()}
                                                                    </Input>
                                                              </FormGroup>
                                                          </Col>
                                                        <Col md={3}>
                                                              <FormGroup>
                                                                <Label for="txtReceiptNo" className="form-label">
                                                                  Receipt No:
                                                                </Label>
                                                                <Input required type="text" id="txtReceiptNo" value={details.ReceiptNo} onChange={(e) => setDetails({ ...details, ReceiptNo: e.target.value })} />
                                                              </FormGroup>
                                                        </Col>
                                                  </Row>
                                                  <Row>
                                                        <Col md={8}>
                                                              <FormGroup check> 
                                                                <Input type="checkbox" id="chkRoomReceiptReturned" checked={details.RoomReceiptReturned} onChange={(e) => setDetails({ ...details, RoomReceiptReturned: e.target.checked })} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Room Receipt returned?
                                                                </Label>
                                                              </FormGroup>
                                                          </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={8}>
                                                              <FormGroup check>
                                                                <Input type="checkbox" id="chkRoomKeyReturned" checked={details.RoomKeyReturned} onChange={(e) => setDetails({ ...details, RoomKeyReturned: e.target.checked })} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Room Key returned?
                                                                </Label>
                                                              </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={8}>
                                                              <FormGroup check>
                                                                <Input type="checkbox" id="chkRoomKeyLost" checked={details.RoomKeyLost} onChange={(e) => setDetails({ ...details, RoomKeyLost: e.target.checked })} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Room Key Lost?
                                                                </Label>
                                                              </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                                <Button color="primary" type="submit">
                                                                Save
                                                                </Button>
                                                            <br/> 
                                                        </Col>
                                                    </Row>
                                                  </Form>
                                            </TabContent>
                                    </CardBody>
                              </Card>
                        </h5>
                  </Col>
              </div>
        </div>
  );                                                                                            
}

export default CheckOutDetails;
