import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./css/bootstrap.min.css";
import "./css/now-ui-kit.css";
import './index.css';

import Login from "./Login";
import Dashboard from "./components/Dashboard";
import Docs from './components/Docs';
import DocDetails from './components/DocDetails'; 
import RoomRoster from './components/RoomRoster';
import ResidentialGuests from './components/ResidentialGuests';
import NonResidentialGuests from './components/NonResidentialGuests'; 
import GuestDates from './components/GuestDates';       
import Deposits from './components/Deposits';
import DepositDetails from './components/DepositDetails';       
import CheckIns from './components/CheckIns';
import CheckOuts from './components/CheckOuts';
import Reports from './components/Reports';
import ReferenceGuests from './components/ReferenceGuests'; 
import ErrorBoundary from './ErrorBoundary';
      

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
<React.StrictMode>
    <BrowserRouter>
<ErrorBoundary>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="docs" element={<Docs />} />
        <Route path="docDetails" element={<DocDetails />} />
        <Route path="deposits" element={<Deposits />} />
        <Route path="depositDetails" element={<DepositDetails />} />
        <Route path="roomRoster" element={<RoomRoster />} />
        <Route path="resGuests" element={<ResidentialGuests />} />
        <Route path="nonResGuests" element={<NonResidentialGuests />} />
        <Route path="guestDates" element={<GuestDates />} />
        <Route path="checkins" element={<CheckIns />} />
        <Route path="checkouts" element={<CheckOuts />} />
        <Route path="reports" element={<Reports />} />
        <Route path="ReferenceGuests" element={<ReferenceGuests />} />
      </Routes>
</ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);