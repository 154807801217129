import React, {useEffect} from "react";
import {arrBlocks} from '.././constants/Blocks';

function RoomOccupancy({data}) {

    const currentYear = new Date().getFullYear(); 

    useEffect(() => {
        printRoomOccupancyReport()
      }, []);

    const printRoomOccupancyReport = () => {
        var printWindow = window.open(' ', 'Room Occupancy Report', 'height=600,width=800');
        printWindow.document.write('<html><head>');
        printWindow.document.write('</head><body style="font-family:verdana; font-size:14px;width:100%;">');
        printWindow.document.write(document.getElementById("divRoomOccupancy").innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();  

      }

  return (
    <>
      <div className="wrapper">
            <div id="divRoomOccupancy" style={{visibility:'hidden', display:'none'}}>
            <h4 style={{margin: 0, textAlign: 'center'}}>IYF {currentYear}</h4>
                        <h5 style={{margin: 0, textAlign: 'center'}}>PARMARTH NIKETAN, RISHIKESH</h5>
                        <h6 style={{textAlign: 'center'}}>Room Occupancy Report</h6>

                        <table style={{borderSpacing: 0,borderCollapse: 'collapse', position: 'absolute', width: '100%', fontSize: 12}}>
                        <tbody>
                            <tr>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '1%'}}>#</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '10%'}}>Guest Name</td>
                                {/* <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Block Name</td> */}
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Room No.</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Check In</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Check Out</td>
                            </tr>
                            {data.map(item => (
                            <tr>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {data.findIndex(x => x.guest_name === item.guest_name) + 1}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.guest_name}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {arrBlocks[item.block_id-1]} - {item.room_no}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black', whiteSpace: 'nowrap'}}>
                                        {item.actual_arrival_date}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black', whiteSpace: 'nowrap'}}>
                                        {item.actual_departure_date}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
            </div>
      </div>
    </>
  );
}

export default RoomOccupancy;
