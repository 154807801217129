
export const Blocks = {
   1:   'Alaknanda', 
   2:   'Ganga', 
   3:   'Ganga Darshan', 
   4:   'Gomti',
   5:   'Himalaya Darshan',
   6:   'Kaveri',
   7:   'Saraswati',
   8:   'Yamuna', 
   9:   'Yoga Village'
};

export const arrBlocks = [   
   'Alaknanda', 
   'Ganga', 
   'Ganga Darshan', 
   'Gomti',
   'Himalaya Darshan',
   'Kaveri',
   'Saraswati',
   'Yamuna', 
   'Yoga Village'
]; 