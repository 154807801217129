import React, {useEffect, useState} from "react";


// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {Countries} from './constants/Countries';
import {ticketTypes} from './constants/TicketTypes';


function GuestDocDetails({gId}) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();
    const [guestIdDetailId, setGuestIdDetailId] = useState();

    const [idDetails, setIdDetails] = useState({
      GuestName: "",
      TicketTypeIds: [],  
      PassportCountryId: "",
      PassportNo: "",
      PassportIssueDate: "",
      PassportExpiryDate: "",
      VisaCountryId: "",
      VisaNo: "",
      VisaIssueDate: "",
      VisaExpiryDate: "",
      VisaType: "",
      EntryDate: "",
      IndianIdType: "",
      IndianIdNo: "",
      IsPassportVerified: false,
      IsVisaVerified: false,
      IsCFormVerified: false,
      IsEntryStampVerified: false,
      IsFrroUploaded: false,
      IsIndianIdVerified: false,
      IsDeclarationFormVerified: false,
      Comments: ""
    });

    const getGuestIdFromQueryString = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const guestId = urlParams.get('gId');
      return guestId;
    };

    useEffect(() => {

      var guestId = getGuestIdFromQueryString();
      if (guestId === null) {
        guestId = gId;
      }
      setGuestIdDetailId(guestId);
      getGuestDetails(guestId);

    }, [gId]);
    

    const getGuestDetails = async (guestIdDetailId) => {
      return fetch("/apiDocs/" + guestIdDetailId)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
            var data = result[0];
            
            setIdDetails({  
              GuestName : data.guest_name,   
              TicketTypeIds: data.ticket_type_ids.split(", "),            
              PassportCountryId: data.country_id_passport,
              PassportNo: data.passport_no,
              PassportIssueDate: data.passport_issue_date,
              PassportExpiryDate: data.passport_expiry_date,
              VisaCountryId: data.country_id_visa,
              VisaNo: data.visa_no,
              VisaIssueDate: data.visa_issue_date,
              VisaExpiryDate: data.visa_expiry_date,
              VisaType: data.visa_type,
              EntryDate: data.entry_date,
              IndianIdType: data.indian_id_type,
              IndianIdNo: data.indian_id_no,
              IsPassportVerified: data.is_passport_verified,
              IsVisaVerified: data.is_visa_verified,
              IsCFormVerified: data.is_cform_verified,
              IsEntryStampVerified: data.is_entrystamp_verified,
              IsFrroUploaded: data.is_frro_uploaded,
              IsIndianIdVerified: data.is_indianid_verified,
              IsDeclarationFormVerified: data.is_declarationform_verified,
              Comments: data.comments
            });

      })

    }

    const populateCountries = () => {
      let items = [];
      items = Object.keys(Countries).map((key) => (
        <option key={key} value={key}>{Countries[key]}</option>
      ));

      return items;
    }

    const updateIdDetails = async (e) => {

      e.preventDefault();
      
      const payload = {

        guest_id_detail_id: guestIdDetailId,
        country_id_passport: idDetails.PassportCountryId === "" ? null : idDetails.PassportCountryId, 
        passport_no: idDetails.PassportNo === "" ? null : idDetails.PassportNo,
        passport_issue_date: idDetails.PassportIssueDate === "0000-00-00" ? null : idDetails.PassportIssueDate, 
        passport_expiry_date: idDetails.PassportExpiryDate === "0000-00-00" ? null : idDetails.PassportExpiryDate,
        country_id_visa: idDetails.VisaCountryId === "" ? null : idDetails.VisaCountryId,
        visa_no: idDetails.VisaNo === "" ? null : idDetails.VisaNo,
        visa_issue_date: idDetails.VisaIssueDate === "0000-00-00" ? null : idDetails.VisaIssueDate,
        visa_expiry_date: idDetails.VisaExpiryDate === "0000-00-00" ? null : idDetails.VisaExpiryDate,
        visa_type: idDetails.VisaType === "" ? null : idDetails.VisaType,
        entry_date: idDetails.EntryDate === "0000-00-00" ? null : idDetails.EntryDate,
        indian_id_type: idDetails.IndianIdType === "" ? null : idDetails.IndianIdType,
        indian_id_no: idDetails.IndianIdNo === "" ? null : idDetails.IndianIdNo,
        is_passport_verified: idDetails.IsPassportVerified,
        is_visa_verified: idDetails.IsVisaVerified,
        is_cform_verified: idDetails.IsCFormVerified,
        is_entrystamp_verified: idDetails.IsEntryStampVerified,
        is_frro_uploaded: idDetails.IsFrroUploaded,
        is_indianid_verified: idDetails.IsIndianIdVerified,
        is_declarationform_verified: idDetails.IsDeclarationFormVerified,
        comments: idDetails.Comments
    
      };
      
      const response = await store("/apiDocs/" + guestIdDetailId, JSON.stringify(payload))
      .then((response) => {
        if (response.status === 200) {
          setAlertMsg(idDetails.GuestName + " details saved successfully.");
          setAlertColor("success");
          setShowAlert(true);
        
          setTimeout(() => {
              if (getGuestIdFromQueryString() !== null) {
                window.close(); 
              } else {
                document.getElementById("frmDocDetails").submit();
              }
          }, 2000); // Delay of 2 seconds

        } else {
          setAlertMsg("Oops, there has been issue. Please try again.")
          setAlertColor("danger");
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setAlertMsg(error.response.data.message)
        setAlertColor("danger");
        setShowAlert(true);
      });
    }
      
  return (
          <div className="wrapper">
              <div id="divGuestDocDetails" className="divDetails">
                    <Col className="text-left" md="12">
                        <h5 className="description">
                              <Card>
                                    <CardHeader>{idDetails.GuestName}</CardHeader>
                                    <CardBody>

                                    {idDetails.TicketTypeIds.map((guestTicketType, index) => (
                                            <div className="divTicketTypes" key={index}>{ticketTypes[guestTicketType]}</div>
                                        ))}

      
                                            <TabContent className="text-left">                                       
                                            <Form id="frmDocDetails" onSubmit={(e) => updateIdDetails(e)}>
                                            <Alert isOpen={showAlert} color={alertColor}>
                                              {alertMsg}
                                            </Alert>
                                                  <div id="divNonIndianDetails">
                                                      <Row>
                                                            <Col md={3}>
                                                                  <FormGroup>
                                                                    <Label for="slPassportCountry" className="form-label">
                                                                      Country of Passport
                                                                    </Label>
                                                                    <Input type="select" id="slPassportCountry" className="form-control" value={idDetails.PassportCountryId} 
                                                                      onChange={(e) => setIdDetails({ ...idDetails, PassportCountryId: e.target.value })} >

                                                                      <option key={0} value={""}>Please select a Country</option>
                                                                      {populateCountries()}
                                                                    </Input>
                                                                  </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                  <FormGroup>
                                                                    <Label for="txtPassportNo" className="form-label">
                                                                      Passport No.
                                                                    </Label>
                                                                  <Input type="text" id="txtPassportNo" value={idDetails.PassportNo} onChange={(e) => setIdDetails({ ...idDetails, PassportNo: e.target.value })} />
                                                                  </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                  <FormGroup>
                                                                    <Label for="txtPassportIssueDate" className="form-label">
                                                                      Passport Issue Date
                                                                    </Label>
                                                                    <Input type="date" id="txtPassportIssueDate" value={idDetails.PassportIssueDate} onChange={(e) => setIdDetails({ ...idDetails, PassportIssueDate: e.target.value })} />
                                                                  </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                  <FormGroup>
                                                                    <Label for="txtPassportExpiryDate" className="form-label">
                                                                      Passport Expiry Date
                                                                    </Label>
                                                                    <Input type="date" id="txtPassportExpiryDate" value={idDetails.PassportExpiryDate} onChange={(e) => setIdDetails({ ...idDetails, PassportExpiryDate: e.target.value })} />
                                                                  </FormGroup>
                                                            </Col>
                                                      </Row>
                                                      <Row> 
                                                          <Col md={4}>
                                                                <FormGroup>
                                                                  <Label for="slVisaCountry" className="form-label">
                                                                    Country of Visa
                                                                  </Label>
                                                                  <Input type="select" id="slVisaCountry" className="form-control" value={idDetails.VisaCountryId}
                                                                    onChange={(e) => setIdDetails({ ...idDetails, VisaCountryId: e.target.value })} >
                                                                    {populateCountries()}
                                                                  </Input>
                                                                </FormGroup>
                                                          </Col>
                                                          <Col md={4}>
                                                                <FormGroup>
                                                                  <Label for="txtVisaType" className="form-label">
                                                                    Visa Type
                                                                  </Label>
                                                                  <Input type="text" id="txtVisaType" value={idDetails.VisaType} onChange={(e) => setIdDetails({ ...idDetails, VisaType: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>
                                                          <Col md={4}>
                                                                <FormGroup>
                                                                  <Label for="txtVisaNo" className="form-label">
                                                                    Visa No.
                                                                  </Label>
                                                                  <Input type="text" id="txtVisaNo" value={idDetails.VisaNo} onChange={(e) => setIdDetails({ ...idDetails, VisaNo: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>
                                                          <Col md={3}>
                                                                <FormGroup>
                                                                  <Label for="txtVisaIssueDate" className="form-label">
                                                                    Visa Issue Date
                                                                  </Label>
                                                                  <Input type="date" id="txtVisaIssueDate" value={idDetails.VisaIssueDate} onChange={(e) => setIdDetails({ ...idDetails, VisaIssueDate: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>
                                                          <Col md={3}>
                                                                <FormGroup>
                                                                  <Label for="txtVisaExpiryDate" className="form-label">
                                                                    Visa Expiry Date
                                                                  </Label>
                                                                  <Input type="date" id="txtVisaExpiryDate" value={idDetails.VisaExpiryDate} onChange={(e) => setIdDetails({ ...idDetails, VisaExpiryDate: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>   
                                                          <Col md={3}>
                                                                <FormGroup>
                                                                  <Label for="txtEntryDate" className="form-label">
                                                                    India Entry Date
                                                                  </Label>
                                                                  <Input type="date" id="txtEntryDate" value={idDetails.EntryDate} onChange={(e) => setIdDetails({ ...idDetails, EntryDate: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>                                            
                                                      </Row>
                                                      <Row>
                                                            <Col md={3}>
                                                                  <FormGroup check> 
                                                                    <Input type="checkbox" id="chkPassportVerified" checked={idDetails.IsPassportVerified} onChange={(e) => setIdDetails({ ...idDetails, IsPassportVerified: e.target.checked })} /> 
                                                                        {' '}
                                                                    <Label check>
                                                                      Is Passport Verified?
                                                                    </Label>
                                                                  </FormGroup>
                                                              </Col>
                                                            <Col md={3}>
                                                                  <FormGroup check>
                                                                    <Input type="checkbox" id="chkVisaVerified" checked={idDetails.IsVisaVerified} onChange={(e) => setIdDetails({ ...idDetails, IsVisaVerified: e.target.checked })} /> 
                                                                        {' '}
                                                                    <Label check>
                                                                      Is Visa Verified?
                                                                    </Label>
                                                                  </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                  <FormGroup check>
                                                                    <Input type="checkbox" id="chkCFormVerified" checked={idDetails.IsCFormVerified} onChange={(e) => setIdDetails({ ...idDetails, IsCFormVerified: e.target.checked })} /> 
                                                                        {' '}
                                                                    <Label check>
                                                                      Is C Form Verified?
                                                                    </Label>
                                                                  </FormGroup>
                                                            </Col>
                                                            </Row>
                                                            <Row>
                                                              <Col md={3}>
                                                                    <FormGroup check>
                                                                      <Input type="checkbox" id="chkEntryStampVerified" checked={idDetails.IsEntryStampVerified} onChange={(e) => setIdDetails({ ...idDetails, IsEntryStampVerified: e.target.checked })} /> 
                                                                          {' '}
                                                                      <Label check>
                                                                        Is Entry Stamp Verified?
                                                                      </Label>
                                                                    </FormGroup>
                                                              </Col>                                   
                                                              <Col md={3}>
                                                                    <FormGroup check>
                                                                      <Input type="checkbox" id="chkFrroUploaded" checked={idDetails.IsFrroUploaded} onChange={(e) => setIdDetails({ ...idDetails, IsFrroUploaded: e.target.checked })} /> 
                                                                          {' '}
                                                                      <Label check>
                                                                        Is FRRO Uploaded?
                                                                      </Label>
                                                                    </FormGroup>
                                                              </Col>
                                                        </Row>  
                                                  </div>
                                                    <br/>
                                                  <div id="divIndianDetails">
                                                      <Row>
                                                        <Col md={4}>
                                                                <FormGroup>
                                                                  <Label for="txtIndianIdType" className="form-label">
                                                                    Indian ID Type
                                                                  </Label>
                                                                  <Input type="text" id="txtIndianIdType" value={idDetails.IndianIdType} onChange={(e) => setIdDetails({ ...idDetails, IndianIdType: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>
                                                          <Col md={4}>
                                                                <FormGroup>
                                                                  <Label for="txtIndianIdNo" className="form-label">
                                                                    Indian ID No.
                                                                  </Label>
                                                                  <Input type="text" id="txtIndianIdNo" value={idDetails.IndianIdNo} onChange={(e) => setIdDetails({ ...idDetails, IndianIdNo: e.target.value })} />
                                                                </FormGroup>
                                                          </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col md={4}>
                                                              <FormGroup check>
                                                                <Input type="checkbox" id="chkIndianIdVerified" checked={idDetails.IsIndianIdVerified} onChange={(e) => setIdDetails({ ...idDetails, IsIndianIdVerified: e.target.checked })} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Is Indian ID Verified?
                                                                </Label>
                                                              </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                              <FormGroup check>
                                                                <Input type="checkbox" id="chkDeclarationFormVerified" checked={idDetails.IsDeclarationFormVerified} onChange={(e) => setIdDetails({ ...idDetails, IsDeclarationFormVerified: e.target.checked })} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Is Declaration Form Verified?
                                                                </Label>
                                                              </FormGroup>
                                                        </Col>
                                                      </Row>
                                                      </div>
                                                      <br/>
                                                      <Row>
                                                        <Col md={12}>
                                                              <FormGroup>
                                                                <Label for="txtComments" className="form-label">
                                                                  Comments
                                                                </Label>
                                                                <textarea id="txtComments" value={idDetails.Comments} onChange={(e) => setIdDetails({ ...idDetails, Comments: e.target.value })} />
                                                              </FormGroup>
                                                        </Col>
                                                      </Row>                                                  
                                                   
                                                  <Row>
                                                    <Col md={12}>
                                                      
                                                            <Button color="primary" type="submit">
                                                            Save
                                                            </Button>
                                                   
                                                        <br/> 
                                                    </Col>
                                                </Row>
                                            </Form>
                                      </TabContent>
                                </CardBody>
                            </Card>
                        </h5>
                  </Col>
              </div>
        </div>
  );                                                                                            
}

export default GuestDocDetails;
