
import React, { useState, useEffect } from 'react';
import { store, destroy } from './utils/httpUtil';
import { logError, checkError } from './utils/helpers';
import {Blocks} from './constants/Blocks';



import { ReactSearchAutocomplete } from 'react-search-autocomplete';

// reactstrap components
import {  Alert, Modal, ModalBody, Container, Input, Button, Table, Label, Row, Col, FormGroup } from "reactstrap";

// core components
import Header from "./Header";
import isOnline from 'is-online';

import imgSrc from '../img/delete.png';

import { useNavigate } from "react-router-dom";

var arrDataSource = [];

const RoomRoster = () => {

      const navigate = useNavigate();

      const [modalRA, setModalRA] = React.useState(false);
      const [tableData, setTableData] = useState([]);
      const [showAlert, setShowAlert] = useState(false);
      const [alertColor, setAlertColor] = useState();
      const [alertMsg, setAlertMsg] = useState();
      const [arrNotesValue, setNotesValue] = useState([]); 
      const [arrMasterGuestData, setMasterGuestData] = useState([]);

      const loadGuestData = () => {

        if (sessionStorage.getItem('authenticated') === 'true'){
          var totalCount = 0;
          return fetch("/apiRoomRoster/")
            .then((response) => {
              totalCount = response.headers.get('X-Total-Count');
              return response.json()
            })
            .then((result) => {

              var arrGuestDataSource = []; 

              for (let i = 0; i < parseInt(totalCount); ++i) {
                arrGuestDataSource.push({ 
                  id: result[i].guest_id_detail_id, 
                  name: result[i].guest_name, 
                  type_name: result[i].type_name,
                  preferred_room_mate: result[i].preferred_room_mate, 
                  gender: result[i].gender
                });
              }
              setDataSource(arrGuestDataSource);
              setMasterGuestData(arrGuestDataSource);
              return arrGuestDataSource;
            })
        } else {
          navigate("/", { replace: true });
        }

      }

      const [dataSource, setDataSource] = useState(loadGuestData);

      const populateBlocks = () => {
        let items = [];
        items = Object.keys(Blocks).map((key) => (
          <option key={key} value={key}>{Blocks[key]}</option>
        ));
  
        return items;
      }

      const handleOnSelect = (roomIndex, guestIndex, guestIdDetailId, guestName, roomId, oldGuestIdDetailId) => {

        //setGuestDetailsFlag(false);

        const copyDataSource = [...dataSource];
        for (let j = 0; j < copyDataSource.length; j++) {
            if (copyDataSource[j].id === guestIdDetailId) {
              copyDataSource.splice(j, 1);
              break;
            }
        }
        setDataSource(copyDataSource);

        const copyTableData = [...tableData];
        const guests = copyTableData[roomIndex].guests[0];
        guests[guestIndex] = { guest_id_detail_id: guestIdDetailId, guest_name: guestName };
        setTableData(copyTableData);

        updateRoomGuest(guestIdDetailId, roomId, oldGuestIdDetailId); 
      }

      const updateRoomGuest = async (guestIdDetailId, roomId, oldGuestIdDetailId) => {

        const payload = {
          guest_id_detail_id: guestIdDetailId,
          room_id: roomId,
          old_guest_id_detail_id: oldGuestIdDetailId  === null ? null : oldGuestIdDetailId  
        };

          const response = await store("/apiRoomRoster/", JSON.stringify(payload))
            .then((response) => {
              if (response.status !== 200) {
                setAlertMsg("Oops, there has been issue. Please try again.")
                setAlertColor("danger");
                setShowAlert(true);
              }
            })
            .catch((error) => {
              setAlertMsg(error.response.data.message)
              setAlertColor("danger");
              setShowAlert(true);
            });
      }

      const handleOnClear = (roomId, guestIdDetailId) => {

        const copyDataSource = [...dataSource];

        for (let i = 0; i < arrMasterGuestData.length; i++) {
          if (arrMasterGuestData[i].id === guestIdDetailId) {
            copyDataSource.push(arrMasterGuestData[i]);
            break;
          }
        }
        setDataSource(copyDataSource);


        handleDelete(roomId, guestIdDetailId);  
      }

      async function handleDelete(roomId, guestIdDetailId) {  

        if (guestIdDetailId != null) {

            const copyTableData = [...tableData];
            for (let i = 0; i < copyTableData.length; i++) {
              const guests = copyTableData[i].guests[0];
              for (let j = 0; j < guests.length; j++) {
                if (guests[j].guest_id_detail_id === guestIdDetailId) {
                  guests.splice(j, 1);
                  break;
                }
              }
            }
            setTableData(copyTableData);
            
            const response = await destroy("/apiRoomRoster/", guestIdDetailId)
              .then((response) => {
                if (response.status !== 200) {
                  setAlertMsg("Oops, there has been issue. Please try again.")
                  setAlertColor("danger");
                  setShowAlert(true);
                } else {
                  loadGuestData();  
                }
              })
              .catch((error) => {
                setAlertMsg(error.response.data.message)
                setAlertColor("danger");
                setShowAlert(true);
              });
        }
      }
    
      const handleNotesChange = async (event, index) => {  
          const newArrNotes = [...arrNotesValue];
          newArrNotes[index] = event.target.value;
          setNotesValue(newArrNotes);
      }

      const handleNotesSave = (index, roomId) => {
        saveNotes(index, roomId, arrNotesValue[index]); 
      }

      const handleNotesClear = (i, roomId) => {

        const newArrNotes = [...arrNotesValue];
        newArrNotes[i] = "";
        setNotesValue(newArrNotes);

        saveNotes(i, roomId, "");
      }

      const saveNotes = async (index, roomId, notes) => { 

        const payload = {
          notes: notes  
        };

        const response = await store("/apiRoomRoster/" + roomId, JSON.stringify(payload))
          .then((response) => {
            if (response.status !== 200) {
              setAlertMsg("Oops, there has been issue. Please try again.")
              setAlertColor("danger");
              setShowAlert(true);
            }
          })
          .catch((error) => {
            setAlertMsg(error.response.data.message)
            setAlertColor("danger");
            setShowAlert(true);
          });
      }
      
      const formatResult = (item) => {
        return (
          <div style={{ display: 'block' }}>
              <span className="result-span">{item.name} {item.gender === 'Male' || item.gender === 'male' ? "(M)" : item.gender === 'Female' || item.gender === 'female' ? "(F)" : "(O)"}
              </span>
              <span className="result-type-span">{item.type_name} </span>
              <span className="result-room-mate-span">{item.preferred_room_mate} </span>
          </div>
        )
      }

      const loadBlockRooms = (BlockId) => {

        var totalCount = 0; 
        arrDataSource = [];
        arrNotesValue.length = 0;  
        setTableData([]);

        fetch("/apiRoomRoster/" + BlockId)
          .then((response) => {
            console.log(response);  
            totalCount = response.headers.get('X-Total-Count');
            return response.json();
          })
          .then((result) => { 

            for (let i = 0; i < parseInt(totalCount); ++i) {
              const guestData = [];

              // Push single entries into arrDataSource
              arrDataSource.push({
                  room_id: result[i].room_id,
                  block_id: result[i].block_id,
                  room_no: result[i].room_no,
                  total_beds: result[i].total_beds,
                  floor_no: result[i].floor_no, 
                  notes: result[i].notes,
                  guests: guestData
              });


              arrNotesValue.push(result[i].notes);            

              var arrGuest = [];

              // Loop through properties of result[i]
              Object.keys(result[i]).forEach((key) => {
                  if (key.startsWith('guest_')) {
                      
                      var arrGuestDetails = [];
                      const guestDet = result[i][key]; 
                      
                    // Push guest properties into guestData object                  
                        if (guestDet === null) {
                            arrGuest.push({
                                guest_id_detail_id: null,
                                guest_name: null,  
                              });
                            return;
                        } else {                           
                                                     
                            arrGuestDetails = guestDet.split(' + '); 
                            arrGuest.push({
                              guest_id_detail_id: arrGuestDetails[0],
                              guest_name: arrGuestDetails[1],  
                            });                             
                        }  
                  } //end of guest_ if                 
              });
              guestData.push(arrGuest);            
            }
            setTableData(arrDataSource);
          })
      }

      const [selectedBlock, setMainBlock] = useState(0);

      const selectBlockRooms = (BlockId) => {  
        setMainBlock(BlockId);
        loadBlockRooms(BlockId); 
      }

      useEffect(() => {          
          return () => {
            arrDataSource = [];
          };
      }, true);

      function handleSearchRoomNo(searchCriteria) {
        if (searchCriteria === "") {
          setTableData(arrDataSource);
          return;
        } else {
            const filteredData = arrDataSource.filter(item => item.room_no.includes(searchCriteria));
            setTableData(filteredData);  
        }
      }

      if (sessionStorage.getItem("has_room_allocation_view") === 'false' || sessionStorage.getItem("has_room_allocation_view") === '0'){
        return (
          <>
            <Header selected="Rooms" />
            <div className="divErrorMsg">Sorry, no access!</div>
          </>
        );
      } else {
            return (
              <>
                    <Modal
                        modalClassName="modal-mini modal-danger"
                        toggle={() => setModalRA(false)}
                        isOpen={modalRA}>
                        <div className="modal-header justify-content-center">
                          No internet!
                        </div>
                        <ModalBody>
                          <p>Oops, you are offline! Your data might not have saved.</p>
                        </ModalBody>
                    </Modal>
                      <Header selected="Room Roster" />
                      <div className="wrapper">
                        <div className="section section-about-us">
                        <Container>
                          <h2 className="title">Room Roster</h2>

                          <Alert isOpen={showAlert} color={alertColor}>
                                {alertMsg}
                          </Alert>

                          <Row>
                          <Col md={3}>
                          <FormGroup>
                              <Input type="select" required id="slMainBlock" className="form-control" value={selectedBlock} onChange={(e) => selectBlockRooms(e.target.value)} >
                                  <option key={0} value="0">Please select a Block</option>
                                  {populateBlocks()}
                              </Input>  
                            </FormGroup> 
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                          <FormGroup>
                              <h2>{Blocks[selectedBlock]}</h2>
                          </FormGroup>
                          </Col>
                          </Row>  


                      {selectedBlock === 0 ? null : 
                          <Table className="table table-striped table-bordered" style={{ fontSize: '12pt'}}>
                            <thead>
                              <tr className="tbl-header tblRoomRoster">
                                  <th style={{width:'1%'}}>#</th>
                                  <th style={{width:'3%'}} className="sticky-col second-col">Room No</th>
                                  <th style={{width:'2%'}}>Total Beds</th>
                                  <th style={{width:'75%'}}>Guests</th>
                                  <th style={{width:'25%'}}>Notes</th> 
                              </tr>
                            </thead>
                            <tbody> 
                                <tr>
                                    <td></td>
                                    <td className="sticky-col first-col">
                                        <input type="text" placeholder="Search Room" onChange={e => handleSearchRoomNo(e.target.value)} style={{width:'125px'}}/>
                                    </td>           
                                </tr>
                                {/* Render table rows */}
                                {tableData.map((row, index) => (
                                  <>
                                    <tr className="trRoomRoster" key={index}>                                        
                                          <td>{index+1}</td>
                                          <td className="sticky-col first-col">
                                            {row.room_no}
                                            <br/>
                                            {row.floor_no === 0 ? "(Ground Floor)" : row.floor_no === 1 ? "(1st Floor)" : row.floor_no === 2 ? "(2nd Floor)" : "(3rd Floor)"}
                                          </td>
                                          <td>{row.total_beds}</td>
                                          <td>    
                                          <div style={{width:'100%'}}>                                       
                                                {Array.from({ length: row.total_beds }, (_, i) => (                                                
                                                    <div key={i} style={{ width: '45%', float:'left', marginBottom:'1em', marginRight:'1em' }}>
                                                          <ReactSearchAutocomplete 
                                                            id={"divGuestName_"+row.room_id}
                                                            items={dataSource}                
                                                            onClear={(item) => { handleOnClear( row.room_id, row.guests[0][i].guest_id_detail_id)} }                                  
                                                            onSelect={(item) => { handleOnSelect(index, i, item.id, item.name, row.room_id, row.guests[0][i] && row.guests[0][i].guest_id_detail_id)}}     
                                                            // showClear={false}                                                     
                                                            formatResult={formatResult}
                                                            maxResults={3}
                                                            placeholder={row.guests[0][i] && row.guests[0][i].guest_name !== null ? row.guests[0][i].guest_name : ''}
                                                            className="guestRoomSearch"
                                                          />
                                                          <div id="divDeleteIcon">
                                                            <img src={imgSrc} alt="delete" style={{ width: 20, height: 20 }} onClick={() => handleDelete(row.room_id, row.guests[0][i].guest_id_detail_id)}/> 
                                                        </div>
                                                    </div>                                                   
                                                ))}
                                          </div>
                                          </td>
                                          <td>
                                              <textarea rows="3" cols="20" value={arrNotesValue[index]} id={"txt_"+index} onChange={(e) => handleNotesChange(e, index)}/>
                                              <Button color="info" size="sm" onClick={(e) => handleNotesSave(index, row.room_id)} >
                                                  Save
                                              </Button>
                                              <Button color="secondary" size="sm" onClick={(e) => handleNotesClear(index, row.room_id)}>
                                                  Clear
                                              </Button>
                                          </td>                                            
                                      </tr>
                                </>
                              ))}
                            </tbody>
                          </Table>
                      }
                          </Container>
                        </div>
                      </div>
              </>
            );
      }
}

export default () => <RoomRoster />
 