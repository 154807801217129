import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {ticketTypes} from './constants/TicketTypes';


function NonResGuestDetails({gId}) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();    
    const [guestIdDetailId, setGuestIdDetailId] = useState();

    const [details, setDetails] = useState({
      GuestName: "",  
      TicketTypes: [],
      ExpectedArrivalDate: "",
      ExpectedDepartureDate: ""
    });

    useEffect(() => {      
      setGuestIdDetailId(gId);
      getGuestDetails(gId);

    }, [gId]);

    const getGuestDetails = async (guestIdDetailId) => {
      return fetch("/apiGuestRoom/" + guestIdDetailId + "?tp=0")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
           var data = result[0];
            
            setDetails({
              GuestName: data.guest_name, 
              TicketTypes: data.ticket_type_ids.split(", "),  
              ExpectedArrivalDate: data.expected_arrival_date,
              ExpectedDepartureDate: data.expected_departure_date
            });
      })
    }


    const assignRoom = async () => {  

        const payload = {
            expected_arrival_date: details.ExpectedArrivalDate === "" ? null : details.ExpectedArrivalDate,
            expected_departure_date: details.ExpectedDepartureDate === "" ? null : details.ExpectedDepartureDate
          };
 
        const response = await store("/apiGuestRoom/?gId=" + guestIdDetailId, JSON.stringify(payload))
        .then((response) => {
          if (response.status === 200) {
            setAlertMsg(details.GuestName + " converted to residential successfully.");
            setAlertColor("success");
            setShowAlert(true);
  
            setTimeout(() => {
                document.getElementById("frmNonResGuests").submit();
              }, 3000); // Delay of 3 seconds
  
          } else {
            setAlertMsg("Oops, there has been issue. Please try again.")
            setAlertColor("danger");
            setShowAlert(true);
          }
        })
        .catch((error) => {
          setAlertMsg(error.response.data.message)
          setAlertColor("danger");
          setShowAlert(true);
        });
    }
      
  return (
          <div className="wrapper">
              <div id="divRoomDepositDetails" className="divDetails">
                    <Col className="text-left" md="7">
                        <h5 className="description">
                              <Card>
                                    <CardHeader>{details.GuestName}</CardHeader>
                                    <CardBody>
                                            <div className="divTicketTypes">
                                            {details.TicketTypes.map((guestTicketType, index) => (
                                            <div className="divTicketTypes" key={index}>{ticketTypes[guestTicketType]}</div>
                                            ))}
                                            </div>
                                            <TabContent className="text-left">                                       
                                            <Form id="frmNonResGuests" onSubmit={() => assignRoom()}>
                                            <Alert isOpen={showAlert} color={alertColor}>
                                              {alertMsg}
                                            </Alert>
                                                  <Row>                                         
                                                        <Col md={6}>
                                                              <FormGroup> 
                                                                <Label for="txtExpectedArrivalDate" className="form-label">
                                                                    Expected Arrival Date:
                                                                </Label>
                                                                    <Input type="date" id="txtExpectedArrivalDate" value={details.ExpectedArrivalDate} onChange={(e) => setDetails({ ...details, ExpectedArrivalDate: e.target.value })} />                          
                                                              </FormGroup>
                                                          </Col>
                                              
                                                        <Col md={6}>
                                                              <FormGroup>
                                                                <Label for="txtExpectedDepartureDate" className="form-label">
                                                                    Expected Departure Date:
                                                                </Label>
                                                                    <Input type="date" id="txtExpectedDepartureDate" value={details.ExpectedDepartureDate} onChange={(e) => setDetails({ ...details, ExpectedDepartureDate: e.target.value })}/>
                                                              </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={11}>
                                                                <Button color="info" type="submit">
                                                                    Convert to Residential    
                                                                </Button>
                                                            <br/> 
                                                        </Col>
                                                    </Row>
                                                  </Form>
                                            </TabContent>
                                    </CardBody>
                              </Card>
                        </h5>
                  </Col>
              </div>
        </div>
  );                                                                                            
}

export default NonResGuestDetails;
