


export function logError(error) {
    const payload = {
        error_message: error.toString()
    }

    fetch("/apiError/", {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
  
      })
      .then(function(response) {
          return response.json()
      })
}

export function checkError(response) {
    if (response.status === 200) {
        return response.data;
      } 
     else if (response.status === 403){
        this.failedLogin();
     } 
    else {
        throw new Error(response.statusText);
    }
}



export function failedLogin(){
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userName');
    
    //notify.show("User doesn't have access to portal!", "error");

    setTimeout(this.redirectAgain, 2000);
}