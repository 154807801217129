import React, { useEffect } from "react";
import moment from "moment";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent
} from "reactstrap";

// core components
import Header from "./Header";
import { useNavigate } from "react-router-dom";


function Dashboard() {

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('authenticated') === 'true'){
      loadData();
    } else {
      navigate("/", { replace: true });
    }
  },true);

    const loadData = () => {
        return fetch("/apiDashboard/")
          .then((response) => {
            return response.json()
          })
          .then((result) => {
              document.getElementById("spExpectedArrivalsCount").innerHTML =  result[0].expected_arrivals;
              document.getElementById("spExpectedDeparturesCount").innerHTML =   result[0].expected_departures; 
              document.getElementById("spCheckInsCount").innerHTML =   result[0].checkins;
              document.getElementById("spCheckOutsCount").innerHTML =   result[0].checkouts;
              document.getElementById("spResAttendeesCount").innerHTML =   result[0].today_residential;
              document.getElementById("spNonResAttendeesCount").innerHTML =   result[0].today_non_residential;
              document.getElementById("spDayPassesCount").innerHTML =   result[0].today_day_pass;
              document.getElementById("spTotalCheckInsCount").innerHTML =   result[0].total_checkins;
              document.getElementById("spTotalCheckOutsCount").innerHTML =   result[0].total_checkouts;
          })
    }

  return (
    <>
      <Header selected="Dashboard"/>
      <div className="wrapper">
      
        <div className="section section-about-us">
          <Container>
          <h2 className="title">Dashboard</h2>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="9">
                <div className="divDashboardHeader">Today's Snapshot - {moment().format('DD-MMM')}</div>

                <Card className="card-sz1" style={{marginRight:'1em'}}>
                  <CardBody>
                      <TabContent className="text-center">
                          <p> 
                          <span className="spRound"><i className="now-ui-icons shopping_shop"></i></span>
                          <div className="divCountsLabel">Check Ins</div>
                          <div className="divSubTextContainer">                   
                              Expected:
                              <span className="divCounts" id="spExpectedArrivalsCount"></span>
                              Actual:
                              <span className="divCounts" id="spCheckInsCount"></span>
                          </div>
                          </p>
                      </TabContent>
                  </CardBody>
                </Card>
                <Card className="card-sz1">
                  <CardBody>
                      <TabContent className="text-center">
                          <p> 
                          <span className="spRound"><i className="now-ui-icons sport_user-run"></i></span>
                          <div className="divCountsLabel">Check Outs</div>
                          <div className="divSubTextContainer">
                              Expected:
                              <span className="divCounts" id="spExpectedDeparturesCount"></span>
                              Actual: 
                              <span className="divCounts" id="spCheckOutsCount"></span>
                          </div>
                          </p>
                      </TabContent>
                  </CardBody>
                </Card>
                <br/>
                <Card className="card-sz2">
                  <CardBody>
                      <TabContent className="text-center">
                          <p> 
                          <span className="spRound"><i className="now-ui-icons users_single-02"></i></span>
                          <div className="divCountsLabel">Attendees</div>
                          <div className="divSubTextContainer">
                              Residential:
                              <span className="divCounts" id="spResAttendeesCount"></span>
                              Non-Residential:
                              <span className="divCounts" id="spNonResAttendeesCount"></span>
                              Day Passes:
                              <span className="divCounts" id="spDayPassesCount"></span>
                          </div> 
                          </p>
                      </TabContent>
                  </CardBody>
                </Card>
                <br/>
                <div className="divDashboardHeader">Total So Far</div>
                <Card className="card-sz3" style={{marginLeft:'1em'}}>
                  <CardBody>
                      <TabContent className="text-center">
                          <p> 
                          <span className="spRound"><i className="now-ui-icons shopping_shop"></i></span>
                          <div className="divCountsLabel">Check Ins</div>
                          <div className="divCounts" id="spTotalCheckInsCount"></div>
                          </p>
                      </TabContent>
                  </CardBody>
                </Card>
                <Card className="card-sz3" style={{marginLeft:'1em'}}>
                  <CardBody>
                      <TabContent className="text-center">
                          <p> 
                          <span className="spRound"><i className="now-ui-icons sport_user-run"></i></span>
                          <div className="divCountsLabel">Check Outs</div>
                          <div className="divCounts" id="spTotalCheckOutsCount"></div>
                          </p>
                      </TabContent>
                  </CardBody>
                </Card>
                {/* </h5> */}
              </Col>
            </Row>           
          </Container>
        </div>

      </div>
    </>
  );
}

export default Dashboard;
