export const ticketTypes = {
    1: 'EARLY BIRD - Top Tier Full Week Residential Pass',
    2: 'EARLY BIRD - Top Tier Single Room Residential Pass',
    3: 'Top Tier Full Week Residential Pass',
    4: 'GROUP DISCOUNT - Top Tier Full Week Res Pass',
    5: 'Top Tier Single Room Full Week Residential Pass',
    6: 'EARLY BIRD - Middle Tier Residential Pass',
    7: 'EARLY BIRD - Middle Tier Single Room Res Pass',
    8: 'Middle Tier Residential Pass',
    9: 'Middle Tier Single Room Residential Pass',
    10: 'GROUP DISCOUNT - Middle Tier Residential Pass',
    11: 'EARLY BIRD - Courtyard Room Residential Pass',
    12: 'EARLY BIRD - Courtyard Single Room Res Pass',
    13: 'Courtyard Room Residential Pass',
    14: 'Courtyard Room Single Room Residential Pass',
    15: 'GROUP DISCOUNT - Courtyard Room Residential Pass',
    16: 'Non-Residential Week Pass',
    17: 'GROUP DISCOUNT - Non-Residential Week Pass',
    18: 'Indian Citizen Week Residential Pass',
    19: 'Day Pass',
    20: 'Donation to the Swami Shukdevanand Trust',
    21: 'Teacher/Presenter Ticket',
    22: 'Sevak Ticket',
    23: 'Infant/Child Residential Pass',
    24: 'Media Day Pass',
    25: 'Media Non-Residential Week Pass',  
    26: 'Media Residential Week Pass',
    27: 'Special Guest Residential Pass',
    28: 'Pre-Festival Accommodation - 4 March',
    29: 'Pre-Festival Accommodation - 5 March',
    30: 'Pre-Festival Accommodation - 6 March',
    31: 'Post-Festival Accommodation - 15 March',
    32: 'Post-Festival Accommodation - 16 March',
    33: 'Post-Festival Accommodation - 17 March',
    34: 'Medical',
    35: 'Orphanage',
    36: 'Ganga Yoga',
    37: 'Misc',
    38: 'Reference Guest'
  };
