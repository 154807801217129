import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Col
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import {store} from './components/utils/httpUtil';
import {checkError} from './components/utils/helpers';
// import axios from "axios";


function Login() {

  const navigate = useNavigate();

  const [username, setusername] = React.useState("");
  const [password, setpassword] = React.useState("");

  const [authenticated, setauthenticated] = React.useState(sessionStorage.getItem(sessionStorage.getItem("authenticated")|| false));


  const handleSubmit = (e) => {
      e.preventDefault();
      checkUserCredentials(username, password);
  }

  function checkUserCredentials(username, password) {

    const payload = {
      u_name: username,
      pwd: password,
    };
  
    store("/apiUser/", JSON.stringify(payload))
    .then((response) => {
      return checkError(response);
    })
    .then((result) => { 
        if (result.length > 0) {
            setauthenticated(true);
            sessionStorage.setItem("authenticated", true);
            sessionStorage.setItem("has_room_allocation_view", result[0].has_room_allocation_view);
            sessionStorage.setItem("has_mandatory_docs_view", result[0].has_mandatory_docs_view);
            sessionStorage.setItem("has_room_deposit_view", result[0].has_room_deposit_view);
            sessionStorage.setItem("has_checkin_checklist_view", result[0].has_checkin_checklist_view);
            sessionStorage.setItem("has_checkout_checklist_view", result[0].has_checkout_checklist_view);
            navigate("/dashboard", { replace: true });  
        } else {
            setauthenticated(false);
            alert("incorrect user name/pwd");
        } 
    })
}


  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="page-header clear-filter" filter-color="orange">
        {/* <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("./img/login.jpg") + ")"
          }}
        ></div> */}
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form className="form">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <img
                        alt="..."
                        src={require("./img/logo.png")}
                      ></img>
                      <div style={{marginTop:'-2em', fontSize:'20pt', color:'white'}}>IYF 2025</div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="User Name..."
                        type="text"
                        value={username}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={(e) => setusername(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="Password..."
                        type="password"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        onChange={(e) => setpassword(e.target.value)}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      size="lg"
                      onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Login;
