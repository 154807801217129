import React from 'react';

const ActionLegend = () => {
    return (
        <div>
            <div className="divLegendParent">
                <div className="divLegend divLegendCompleted"></div>
                Completed
            </div>

            <div className="divLegendParent">
                <div className=" divLegend divLegendPending"></div>
                Pending
            </div>

            <div className="divLegendParent">
                <div className="divLegend divLegendNA"></div>
                NA
            </div>
        </div>
    );
};

export default ActionLegend;
