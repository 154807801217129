import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {Blocks} from './constants/Blocks';
import {ticketTypes} from './constants/TicketTypes';


function GuestDates({gId}) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();    
    const [guestIdDetailId, setGuestIdDetailId] = useState();

    const [details, setDetails] = useState({
      GuestTicketId: "",  
      GuestName: "",  
      TicketTypes: [],
      BlockId: "",
      RoomNo: "",
      ExpectedArrivalDate: "",
      ExpectedDepartureDate: "",
      ActualArrivalDate: "",
      ActualDepartureDate: ""
    });


    const getGuestIdFromQueryString = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const guestId = urlParams.get('gId');
      return guestId;
    };

    useEffect(() => {      

      var guestId = getGuestIdFromQueryString();
      setGuestIdDetailId(guestId);
      getGuestDetails(guestId);

    }, []);

    const getGuestDetails = async (guestIdDetailId) => {
      return fetch("/apiGuestRoom/" + guestIdDetailId)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
           var data = result[0];
            
            setDetails({
              GuestTicketId: data.guest_id_ticket_type_id,
              GuestName: data.guest_name, 
              TicketTypes: data.ticket_type_ids.split(", "),  
              BlockId: data.block_id,
              RoomNo: data.room_no,
              ExpectedArrivalDate: data.expected_arrival_date,
              ExpectedDepartureDate: data.expected_departure_date,  
              ActualArrivalDate: data.actual_arrival_date,
              ActualDepartureDate: data.actual_departure_date
            });
      })
    }

    const updateGuestDates = async (GuestTicketId) => {
     
      const payload = {
        guest_id_detail_id: guestIdDetailId,
        expected_arrival_date: details.ExpectedArrivalDate === "" ? null : details.ExpectedArrivalDate,
        expected_departure_date: details.ExpectedDepartureDate === "" ? null : details.ExpectedDepartureDate,
        actual_arrival_date: details.ActualArrivalDate === "" ? null : details.ActualArrivalDate,
        actual_departure_date: details.ActualDepartureDate === "" ? null : details.ActualDepartureDate
      };
      
      const response = await store("/apiGuestRoom/" + GuestTicketId, JSON.stringify(payload))
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          setAlertMsg(details.GuestName + " dates saved successfully.");
          setAlertColor("success");
          setShowAlert(true);

          setTimeout(() => {
              window.close(); 
          }, 2000); // Delay of 2 seconds

        } else {
          setAlertMsg("Oops, there has been issue. Please try again.")
          setAlertColor("danger");
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setAlertMsg(error.response.data.message)
        setAlertColor("danger");
        setShowAlert(true);
      });
    }
      
  return (
          <div className="wrapper">
              <div id="divRoomDepositDetails" className="divDetails">
                    <Col className="text-left" md="5">
                        <h5 className="description">
                              <Card>
                                    <CardHeader>{details.GuestName}</CardHeader>
                                    <CardBody>
                                            <div className="divTicketTypes">
                                            {details.TicketTypes.map((guestTicketType, index) => (
                                            <div className="divTicketTypes" key={index}>{ticketTypes[guestTicketType]}</div>
                                            ))}
                                            </div>
                                            <TabContent className="text-left">                                       
                                            <Form id="frmGuestDates">
                                            <Alert isOpen={showAlert} color={alertColor}>
                                              {alertMsg}
                                            </Alert>
                                                  <Row>
                                                        <Col md={6}>
                                                              <FormGroup>
                                                              <Label for="txtBlock" className="form-label">
                                                                  Block:
                                                                </Label>
                                                                <Input plaintext readonly type="text" id="txtBlock" value={Blocks[details.BlockId]} />
                                                              </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                              <FormGroup>
                                                                <Label for="txtRoomNo" className="form-label">
                                                                  Room No:
                                                                </Label>
                                                                <Input plaintext readonly type="text" id="txtRoomNo" value={details.RoomNo} />
                                                              </FormGroup>
                                                        </Col>
                                                  </Row>
                                                  <Row>                                         
                                                        <Col md={6}>
                                                              <FormGroup> 
                                                                <Label for="txtExpectedArrivalDate" className="form-label">
                                                                    Expected Arrival Date:
                                                                </Label>
                                                                    <Input type="date" id="txtExpectedArrivalDate" value={details.ExpectedArrivalDate} onChange={(e) => setDetails({ ...details, ExpectedArrivalDate: e.target.value })} />                          
                                                              </FormGroup>
                                                          </Col>
                                              
                                                        <Col md={6}>
                                                              <FormGroup>
                                                                <Label for="txtExpectedDepartureDate" className="form-label">
                                                                    Expected Departure Date:
                                                                </Label>
                                                                    <Input type="date" id="txtExpectedDepartureDate" value={details.ExpectedDepartureDate} onChange={(e) => setDetails({ ...details, ExpectedDepartureDate: e.target.value })}/>
                                                              </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>                                         
                                                        <Col md={6}>
                                                              <FormGroup> 
                                                                <Label for="txtActualArrivalDate" className="form-label">
                                                                    Actual Arrival Date:
                                                                </Label>
                                                                    <Input type="date" id="txtActualArrivalDate" value={details.ActualArrivalDate} onChange={(e) => setDetails({ ...details, ActualArrivalDate: e.target.value })} />                          
                                                              </FormGroup>
                                                          </Col>
                                              
                                                        <Col md={6}>
                                                              <FormGroup>
                                                                <Label for="txtActualDepartureDate" className="form-label">
                                                                    Actual Departure Date:
                                                                </Label>
                                                                    <Input type="date" id="txtActualDepartureDate" value={details.ActualDepartureDate} onChange={(e) => setDetails({ ...details, ActualDepartureDate: e.target.value })}/>
                                                              </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={11}>
                                                                <Button color="primary" type="button" onClick={() => updateGuestDates(details.GuestTicketId)}>
                                                                    Save    
                                                                </Button>
                                                            <br/> 
                                                        </Col>
                                                    </Row>
                                                  </Form>
                                            </TabContent>
                                    </CardBody>
                              </Card>
                        </h5>
                  </Col>
              </div>
        </div>
  );                                                                                            
}

export default GuestDates;
