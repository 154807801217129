import React, {useEffect, useState} from "react";


// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup, FormText,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {Blocks} from './constants/Blocks';
import {ticketTypes} from './constants/TicketTypes';
import { useNavigate } from "react-router-dom";
import ActionLegend from './ActionLegend';
import { Link } from "react-router-dom";


function CheckOutDetails({gId}) {

    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();
    const [guestIdDetailId, setGuestIdDetailId] = useState();

    const [details, setDetails] = useState({
      GuestName: "",
      TicketTypeIds: [],
      DayPasses: [],  
      AllDocsVerified: false,
      RoomDepositPaid: false,
      RoomKeyReceived: false,
      WristBandReceived: false,
    //  FoodCouponReceived: false,
      BagReceived: false,
      BlockId: null,
      RoomNo: null
    });

    useEffect(() => { 

        if (sessionStorage.getItem('authenticated') === 'true'){

          setGuestIdDetailId(gId);
          getGuestDetails(gId);

        } else {
            navigate("/", { replace: true });
        }
    }, [gId]);

    const getGuestDetails = async (guestIdDetailId) => {
      return fetch("/apiCheckIn/" + guestIdDetailId)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
            var data = result[0];
            
            setDetails({  
              GuestName : data.guest_name,  
              TicketTypeIds: data.ticket_type_ids.split(", "),    
              DayPasses: data.day_pass_dates ? data.day_pass_dates.split(", ") : null,         
              AllDocsVerified: data.all_docs_verified,
              RoomDepositPaid: data.room_deposit_paid,
              RoomKeyReceived: data.room_key_received,
              WristBandReceived: data.wrist_band_received,
             // FoodCouponReceived: data.food_coupon_received,
              BagReceived: data.bag_received,
              BlockId: data.block_id,
              RoomNo: data.room_no  
            });
      })
    }


    const updateCheckIn = async (e) => {

      e.preventDefault();

      if (details.AllDocsVerified === 0 || details.RoomDepositPaid === 0) {
        setAlertMsg("Please verify required documents/pay room deposit before checking in.")
        setAlertColor("danger");
        setShowAlert(true);
        return;
      }
      
      const payload = {
          key: details.RoomKeyReceived,
          band: details.WristBandReceived,
        //  coupon: null,
          bag: details.BagReceived
      };
      
      const response = await store("/apiCheckIn/" + guestIdDetailId, JSON.stringify(payload))
      .then((response) => {
        if (response.status === 200) {
          setAlertMsg(details.GuestName + " saved successfully.");
          setAlertColor("success");
          setShowAlert(true);

          setTimeout(() => {
            document.getElementById("frmCheckIn").submit();
          }, 2000); // Delay of 3 seconds

        } else {
          setAlertMsg("Oops, there has been issue. Please try again.")
          setAlertColor("danger");
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setAlertMsg(error.response.data.message)
        setAlertColor("danger");
        setShowAlert(true);
      });
    }

    const setColBG = (value) => {
        return ((value === 1 || value === true) ? 'bg-success'
          : (value === 0 || value === false) ? 'bg-warning'
          : 'bg-none');
    }

    const setKeyClick = (value) => {
      setDetails({ ...details, RoomKeyReceived: value })
      setColBG(value);
    }

    const setBandClick = (value) => {
      setDetails({ ...details, WristBandReceived: value })
      setColBG(value);
    }

    const setCouponClick = (value) => {
      setDetails({ ...details, FoodCouponReceived: value })
      setColBG(value);
    }

    const setBagClick = (value) => {
      setDetails({ ...details, BagReceived: value })
      setColBG(value);
    }

      
  return (
          <div className="wrapper">
              <div id="divCheckInDetails" className="divDetails">
                    <Col className="text-left" md="10">
                        <h5 className="description">
                              <Card>
                                    <CardHeader>{details.GuestName}</CardHeader>
                                    <CardBody>
                                        {details.TicketTypeIds.map((guestTicketType, index) => (
                                            <div className="divTicketTypes" key={index}>{ticketTypes[guestTicketType]}</div>
                                        ))}
                                        {(
                                            (details.DayPasses === null)? null : 
                                            (
                                              <>
                                              {details.DayPasses.map((guestDayPass, index) => (
                                                  <div className="spDayPassDates" key={index}>{guestDayPass}</div>
                                                ))
                                              }
                                               </>
                                            )  
                                        )} 
                                            <TabContent className="text-left">                                       
                                            <Form id="frmCheckIn" onSubmit={(e) => updateCheckIn(e)}>
                                            <Alert isOpen={showAlert} color={alertColor}>
                                              {alertMsg}
                                            </Alert>
                                            {details.BlockId === null ? null :
                                            (
                                              <Row>
                                                  <Col md={3}>
                                                      <Label>Block: <b>{Blocks[details.BlockId]}</b></Label>
                                                  </Col>
                                                  <Col md={3}>
                                                      <Label>Room No: <b>{details.RoomNo}</b></Label>
                                                  </Col>
                                              </Row>
                                            )}  
                                                  <Row style={{margin:'0'}}>
                                                        <Col md={2} className={setColBG(details.AllDocsVerified)}>                                                          
                                                              <FormGroup check>    
                                                                <Label>  
                                                                    <Link style={{ textDecoration:'underline', color:'blue'}} to={`/docDetails/?gId=${guestIdDetailId}`} target="_blank" rel="noopener noreferrer">
                                                                      Documents
                                                                    </Link>                                                                                                                     
                                                                </Label>
                                                                </FormGroup>                                                        
                                                        </Col>
                                                        <Col md={2} className={setColBG(details.RoomDepositPaid)}>   
                                                              <FormGroup check>
                                                                <Label>
                                                                <Link style={{ textDecoration:'underline', color:'blue'}} to={`/depositDetails/?gId=${guestIdDetailId}`} target="_blank" rel="noopener noreferrer">
                                                                      Deposit
                                                                </Link>                                                                       
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3} className={setColBG(details.RoomKeyReceived)}>  
                                                              <FormGroup check> 
                                                                <Input type="checkbox" id="chkKey" checked={details.RoomKeyReceived} onChange={(e) => setKeyClick(e.target.checked)} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Key
                                                                </Label>
                                                              </FormGroup>
                                                        </Col> 
                                                        <Col md={2} className={setColBG(details.WristBandReceived)}> 
                                                              <FormGroup check> 
                                                                <Input type="checkbox" id="chkBand" checked={details.WristBandReceived}  onChange={(e) => setBandClick(e.target.checked)} /> 
                                                                    {' '}
                                                                <Label check>
                                                                  Band
                                                                </Label>
                                                              </FormGroup>
                                                        </Col>      
                                                        <Col md={3} className={setColBG(details.BagReceived)}>  
                                                              <FormGroup check>
                                                                <Input type="checkbox" id="chkBag" checked={details.BagReceived} onChange={(e) => setBagClick(e.target.checked)} />
                                                                {' '}
                                                                <Label check>
                                                                    Bag
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                          <FormText>
                                                          <ActionLegend />
                                                        </FormText>
                                                        </Col>
                                                        <Col md={6}>
                                                          <br/>
                                                                <Button color="primary" type="submit">
                                                                Save
                                                                </Button>
                                                            <br/> 
                                                        </Col>
                                                    </Row>
                                                  </Form>
                                            </TabContent>
                                    </CardBody>
                              </Card>
                        </h5>
                  </Col>
              </div>
        </div>
  );                                                                                            
}

export default CheckOutDetails;
