import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {Blocks} from './constants/Blocks';
import {ticketTypes} from './constants/TicketTypes';


function DepositDetails({gId}) {

    const amountValues = {
        1: '$10',
        2: '€10', 
        3: '£10', 
        4: '₹1000'
    };

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();
    
    const [guestIdDetailId, setGuestIdDetailId] = useState();

    const [details, setDetails] = useState({
      GuestName: "",  
      TicketTypes: [],
      GuestRoomId: "",
      BlockId: "",
      RoomNo: "",
      CurrencyId: "",
      ReceiptNo: ""
    });


    const getGuestIdFromQueryString = () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const guestId = urlParams.get('gId');
      return guestId;
    };

    useEffect(() => {

      var guestId = getGuestIdFromQueryString();
      if (guestId === null) {
        guestId = gId;
      }
      setGuestIdDetailId(guestId);
      getGuestDetails(guestId);

    }, [gId]);

    const getGuestDetails = async (guestIdDetailId) => {
      return fetch("/apiCheckIn/?gId=" + guestIdDetailId)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
           var data = result[0];
            
            setDetails({
              GuestName: data.guest_name, 
              TicketTypes: data.ticket_type_ids.split(", "),  
              GuestRoomId: data.guest_room_id,
              BlockId: data.block_id,
              RoomNo: data.room_no,
              CurrencyId: data.currency_id,
              ReceiptNo: data.receipt_no
            });
      })
    }

    const populateAmount = () => {

      let items = [];
      items = Object.keys(amountValues).map((key) => (
        <option key={key} value={key}>{amountValues[key]}</option>
      ));

      return items;
    }

    const updateRoomDeposit = async (e) => {

      e.preventDefault();   

      if (details.BlockId === null || details.RoomNo === null) {
        setAlertMsg("Oops, please assign a room before adding the key deposit details.")
        setAlertColor("danger");
        setShowAlert(true);
        return;
      }

      
      const payload = {
        currency_id: details.CurrencyId,
        receipt_no: details.ReceiptNo.trim()
      };
      
      const response = await store("/apiCheckIn/?grId=" + details.GuestRoomId, JSON.stringify(payload))
      .then((response) => {
        if (response.status === 200) {
          setAlertMsg(details.GuestName + " key deposit details saved successfully.");
          setAlertColor("success");
          setShowAlert(true);

          setTimeout(() => {
            if (getGuestIdFromQueryString() !== null) {
              window.close(); 
            } else {
              document.getElementById("frmRoomDeposit").submit();
            }
          }, 2000); // Delay of 2 seconds

        } else {
          setAlertMsg("Oops, there has been issue. Please try again.")
          setAlertColor("danger");
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setAlertMsg(error.response.data.message)
        setAlertColor("danger");
        setShowAlert(true);
      });
    }
      
  return (
          <div className="wrapper">
              <div id="divRoomDepositDetails" className="divDetails">
                    <Col className="text-left" md="10">
                        <h5 className="description">
                              <Card>
                                    <CardHeader>{details.GuestName}</CardHeader>
                                    <CardBody>
                                            <div className="divTicketTypes">
                                            {details.TicketTypes.map((guestTicketType, index) => (
                                            <div className="divTicketTypes" key={index}>{ticketTypes[guestTicketType]}</div>
                                            ))}
                                            </div>
                                            <TabContent className="text-left">                                       
                                            <Form id="frmRoomDeposit" onSubmit={(e) => updateRoomDeposit(e)}>
                                            <Alert isOpen={showAlert} color={alertColor}>
                                              {alertMsg}
                                            </Alert>
                                                  <Row>
                                                        <Col md={3}>
                                                              <FormGroup>
                                                              <Label for="txtBlock" className="form-label">
                                                                  Block:
                                                                </Label>
                                                                <Input plaintext readonly type="text" id="txtBlock" value={Blocks[details.BlockId]} />
                                                              </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                              <FormGroup>
                                                                <Label for="txtRoomNo" className="form-label">
                                                                  Room No:
                                                                </Label>
                                                                <Input plaintext readonly type="text" id="txtRoomNo" value={details.RoomNo} />
                                                              </FormGroup>
                                                        </Col>
                                         
                                                        <Col md={3}>
                                                              <FormGroup> 
                                                                <Label for="slAmount" className="form-label">
                                                                    Amount:
                                                                    </Label>
                                                                    <Input type="select" required id="slAmount" className="form-control" value={details.CurrencyId} onChange={(e) => setDetails({ ...details, CurrencyId: e.target.value })}>
                                                                    <option key={0} value={""}>Please select one</option>
                                                                    {populateAmount()}
                                                                    </Input>
                                                              </FormGroup>
                                                          </Col>
                                              
                                                        <Col md={3}>
                                                              <FormGroup>
                                                                <Label for="txtReceiptNo" className="form-label">
                                                                    Receipt No:
                                                                </Label>
                                                                    <Input required type="text" id="txtReceiptNo" value={details.ReceiptNo} onChange={(e) => setDetails({ ...details, ReceiptNo: e.target.value })}/>
                                                              </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                        
                                                                <Button color="primary" type="submit">
                                                                    Save    
                                                                </Button>
                                                          
                                                            <br/> 
                                                        </Col>
                                                    </Row>
                                                  </Form>
                                            </TabContent>
                                    </CardBody>
                              </Card>
                        </h5>
                  </Col>
              </div>
        </div>
  );                                                                                            
}

export default DepositDetails;
