import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    Form, FormGroup,
    Row,
    Col,
    Label,
    Input,
    Modal,
    ModalBody,
    Container
  } from "reactstrap";

import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import Header from "./Header";


function ReferenceGuests({}) {

    const [modalCO, setModalCO] = React.useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();    

    const [details, setDetails] = useState({
        FirstName: "", 
        LastName: ""
    });

    useEffect(() => {     
    }, []);


    const saveReferenceGuest = async () => {  

        const payload = {
            f_name: details.FirstName === "" ? null : details.FirstName,
            l_name: details.LastName === "" ? null : details.LastName
            };


        const response = await store("/apiGuestRoom/?tp=0", JSON.stringify(payload))
            .then((response) => {
            if (response.status === 200) {
                setAlertMsg(details.FirstName + " " + details.LastName + " saved successfully.");
                setAlertColor("success");
                setShowAlert(true);
    
            } else {
                setAlertMsg("Oops, there has been issue. Please try again.")
                setAlertColor("danger");
                setShowAlert(true);
            }
            })
            .catch((error) => {
                setAlertMsg(error.response.data.message)
                setAlertColor("danger");
                setShowAlert(true);
            });
    }

    if (sessionStorage.getItem("has_room_allocation_view") === 'false' || sessionStorage.getItem("has_room_allocation_view") === '0') {
        return (
          <>
          <Header selected="Rooms"/>
          <div class="divErrorMsg">Sorry, no access!</div>);
          </> 
      );
    } else {
      
  return (

    <>

    <Modal
      modalClassName="modal-mini modal-danger"
      toggle={() => setModalCO(false)}
      isOpen={modalCO}>
      <div className="modal-header justify-content-center">
        No internet!
      </div>
      <ModalBody>
        <p>Oops, you are offline! Your data might not have saved.</p>
      </ModalBody>
    </Modal>

    <Header selected="Reference Guests"/>
    <div className="wrapper">
    
        <div className="section section-about-us">
            <Container>
                <h2 className="title">Reference Guests</h2>

                <div className="wrapper">
                    <div id="divReferenceGuests" className="divDetails">
                            <Col className="text-left" md="7">
                                <h5 className="description">
                                    <Card>
                                            <CardHeader>Add Reference Guest </CardHeader>
                                            <CardBody>
                                                    <h4>Room only guests</h4>   
                                                    <TabContent className="text-left">                                       
                                                    <Form id="frmReferenceGuests">
                                                    <Alert isOpen={showAlert} color={alertColor}>
                                                    {alertMsg}
                                                    </Alert>
                                                        <Row>                                         
                                                                <Col md={6}>
                                                                    <FormGroup> 
                                                                        <Label for="txtFirstName" className="form-label">
                                                                            First Name:
                                                                        </Label>
                                                                            <Input type="text" id="txtFirstName" value={details.FirstName} onChange={(e) => setDetails({ ...details, FirstName: e.target.value })} />                          
                                                                    </FormGroup>
                                                                </Col>
                                                    
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="txtLastName" className="form-label">
                                                                            Last Name:
                                                                        </Label>
                                                                            <Input type="text" id="txtLastName" value={details.LastName} onChange={(e) => setDetails({ ...details, LastName: e.target.value })}/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={11}>
                                                                        <Button color="info" type="button" onClick={() => saveReferenceGuest()}>
                                                                            Save 
                                                                        </Button>
                                                                    <br/> 
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </TabContent>
                                            </CardBody>
                                    </Card>
                                </h5>
                        </Col>
                    </div>
                </div>
            </Container>
        </div>
    </div>
    </>
  ); 
  }
}

export default ReferenceGuests;
