
import React, { useCallback, useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import Button from '@inovua/reactdatagrid-community/packages/Button';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import '@inovua/reactdatagrid-community/index.css';
import {store} from './utils/httpUtil';
import {logError, checkError} from './utils/helpers';
import {arrBlocks} from './constants/Blocks';
// import {TicketTypes} from './constants/TicketTypes';  

// reactstrap components
import {  Modal, ModalBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

// core components
import Header from "./Header";
import isOnline from 'is-online';

import { useNavigate } from "react-router-dom"; 

const gridStyle = { minHeight: 550, marginTop: 10 };

const defaultFilterValue = [
  { name: 'guest_name', operator: 'contains', type: 'string' },
  { name: 'room_no', operator: 'contains', type: 'string' }
]

var arrDataSource = [];

const columns = [
  { name: 'guest_room_id', header: 'Id', defaultVisible: false, type: 'number'},
  { name: 'guest_name', header: 'Name', defaultFlex: 1, minWidth: 200, enableColumnFilterContextMenu: false, userSelect: true, 
    render: ({ data }) => <span style={{fontWeight: 'bold'}}>{data.guest_name}</span>},

    // render: ({ data }) => <span style={{fontWeight: 'bold'}}>{data.guest_name}<br/><span style={{ fontSize: '8pt'}}>{data.type_name}</span></span>},

  { name: 'expected_arrival_date', groupBy: false, defaultFlex: 1, maxWidth: 150, textAlign: 'center', header: 'Exp. Arrival', userSelect: true },

  { name: 'expected_departure_date', groupBy: false, defaultFlex: 1, maxWidth: 150, textAlign: 'center', header: 'Exp.Departure', userSelect: true },

  { name: 'actual_arrival_date', groupBy: false, defaultFlex: 1, maxWidth: 150, textAlign: 'center', header: 'Act. Arrival', userSelect: true, 
      render: ({ data }) => <span style={{fontWeight: 'bold'}}>{data.actual_arrival_date}<br/></span> },

  { name: 'actual_departure_date', groupBy: false, defaultFlex: 1, maxWidth: 150, textAlign: 'center', header: 'Act. Departure', userSelect: true,
      render: ({ data }) => <span style={{fontWeight: 'bold'}}>{data.actual_departure_date}<br/></span> },
    
  { name: 'block_id', groupBy: false, defaultFlex: 1, maxWidth: 150, textAlign: 'center', header: 'Block', userSelect: true, 
      render: ({ value })=> arrBlocks[value-1]? arrBlocks[value-1] : value
  },

  { name: 'room_no', groupBy: false, defaultFlex: 1, textAlign: 'center', maxWidth: 150, header: 'Room No.', userSelect: true },

  { name: 'ticket_type_ids', groupBy: false, defaultFlex: 1, textAlign: 'center', maxWidth: 100, header: 'Actions', userSelect: true, 
      render: ({ data }) => <Link style={{ textDecoration:'underline', color:'blue'}} to={`/guestDates/?gId=${data.guest_id_detail_id}`} target="_blank" rel="noopener noreferrer">Edit</Link> }

];


const downloadBlob = (blob, fileName = 'guest-rooms.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const SEPARATOR = ',';

const ResidentialGuests = () => {

  const navigate = useNavigate(); 

  const [modalRA, setModalRA] = React.useState(false);

  const loadData = () => {

    if (sessionStorage.getItem('authenticated') === 'true'){  
        var totalCount = 0;
        return fetch("/apiGuestRoom")
          .then((response) => {
            totalCount = response.headers.get('X-Total-Count');
            return response.json()
          })
          .then((result) => {
            for (let i = 0; i < parseInt(totalCount); ++i) {
              arrDataSource.push({ 
                  guest_room_id: result[i].guest_room_id, 
                  guest_name: result[i].guest_name, 
                  // type_ids: result[i].ticket_type_ids,
                  expected_arrival_date: result[i].expected_arrival_date,
                  actual_arrival_date: result[i].actual_arrival_date,
                  expected_departure_date: result[i].expected_departure_date,
                  actual_departure_date: result[i].actual_departure_date,
                  block_id: arrBlocks[(result[i].block_id-1)],
                  room_no: result[i].room_no,
                  guest_id_detail_id: result[i].guest_id_detail_id
              });
            }
            setDataSource(arrDataSource);   
            return arrDataSource;
          })
    } else {
      navigate("/", { replace: true });
    }
  }
  
  const [gridRef, setGridRef] = useState(null);

  const [dataSource, setDataSource] = useState(loadData);

  useEffect(() => {
    return () => {
      arrDataSource = [];
    };
}, []);


  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;
  
    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));
  
    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  
    downloadBlob(blob);
  };

  if (sessionStorage.getItem("has_room_deposit_view") == 'false' || sessionStorage.getItem("has_room_deposit_view") == '0'){
      return (
        <>
        <Header selected="Rooms"/>
        <div class="divErrorMsg">Sorry, no access!</div>);
        </> 
      );
} else {
       return (
        <>
            <Modal
                modalClassName="modal-mini modal-danger"
                toggle={() => setModalRA(false)}
                isOpen={modalRA}>
                <div className="modal-header justify-content-center">
                  No internet!
                </div>
                <ModalBody>
                  <p>Oops, you are offline! Your data might not have saved.</p>
                </ModalBody>
              </Modal>

          <Header selected="Rooms"/>
        <div className="wrapper">
            <div className="section section-about-us">
            <Container>
            <h2 className="title">Residential Guests</h2>
              {/* <div className="App"> */}
                  <ReactDataGrid
                          idProperty="guest_room_id"
                          style={gridStyle}
                          handle={setGridRef}
                          columns={columns}
                          rowHeight={60}
                          dataSource={dataSource}
                          defaultFilterValue={defaultFilterValue}
                        />

          <Button style={{ marginTop: 20 }} onClick={exportCSV}>
                  Export CSV
                </Button>

              {/* </div> */}
              </Container>
          </div>
      </div>
      </>
      );
   }
  }
 
 export default () => <ResidentialGuests />
 