import React, {useEffect} from "react";

import {Countries} from '.././constants/Countries';
import {arrBlocks} from '.././constants/Blocks';

function TicketTypes({data, ticketTypeName}) {

    const currentYear = new Date().getFullYear();   

    useEffect(() => {
        printTicketTypes()
      }, []);   

    const printTicketTypes = () => {
        var printWindow = window.open(' ', 'Ticket Type Report', 'height=600,width=800');
        printWindow.document.write('<html><head>');
        printWindow.document.write('<title>');
        printWindow.document.write(ticketTypeName)
        printWindow.document.write('</title>');
        printWindow.document.write('</head><body style="font-family:verdana; font-size:14px;width:100%;">');
        printWindow.document.write(document.getElementById("divTicketTypes").innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();  

      }

  return (
    <>
      <div className="wrapper">
            <div id="divTicketTypes" style={{visibility:'hidden', display:'none'}}>
            <h4 style={{margin: 0, textAlign: 'center'}}>IYF {currentYear}</h4>
                        <h5 style={{margin: 0, textAlign: 'center'}}>PARMARTH NIKETAN, RISHIKESH</h5>
                        <h6 style={{textAlign: 'center'}}>{ticketTypeName} Report</h6>

                        <table style={{borderSpacing: 0,borderCollapse: 'collapse', position: 'absolute', width: '100%', fontSize: 12}}>
                        <tbody>
                            <tr>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '1%'}}>#</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '20%'}}>Guest Name</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '4%'}}>Gender</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '4%'}}>Country</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '50%'}}>Preferred Room Mate</td>  
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '25%'}}>Room No.</td>                                               
                            </tr>
                            {data.map(item => (
                            <tr>
                             <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {data.findIndex(x => x.guest_name === item.guest_name) + 1}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.guest_name}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black', whiteSpace: 'nowrap'}}>
                                    {item.gender}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black', whiteSpace: 'nowrap'}}>
                                    {Countries[item.country_id_passport]}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.preferred_room_mate}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {arrBlocks[item.block_id-1]} - {item.room_no}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
            </div>
      </div>
    </>
  );
}

export default TicketTypes;
