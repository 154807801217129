export const Countries = {

    1: 'Afghanistan',
    2: 'Albania',
    3: 'Algeria',
    4: 'American Samoa',
    5: 'Andorra',
    6: 'Angola',
    7: 'Anguilla',
    8: 'Antarctica',
    9: 'Antigua & Barbuda',
    10: 'Argentina',
    11: 'Armenia',
    12: 'Aruba',
    13: 'Australia',
    14: 'Austria',
    15: 'Azerbaijan',
    16: 'Bahamas',
    17: 'Bahrain',
    18: 'Bangladesh',
    19: 'Barbados',
    20: 'Belarus',
    21: 'Belgium',
    22: 'Belize',
    23: 'Benin',
    24: 'Bermuda',
    25: 'Bhutan',
    26: 'Bolivia',
    27: 'Bosnia & Herzegovina',
    28: 'Botswana',
    29: 'Bouvet Island',
    30: 'Brazil',
    31: 'British Indian Ocean Territory',
    32: 'British Virgin Islands',
    33: 'Brunei',
    34: 'Bulgaria',
    35: 'Burkina Faso',
    36: 'Burundi',
    37: 'Cambodia',
    38: 'Cameroon',
    39: 'Canada',
    40: 'Cape Verde',
    41: 'Caribbean Netherlands',
    42: 'Cayman Islands',
    43: 'Central African Republic',
    44: 'Chad',
    45: 'Chile',
    46: 'China',
    47: 'Christmas Island',
    48: 'Cocos (Keeling) Islands',
    49: 'Colombia',
    50: 'Comoros',
    51: 'Congo - Brazzaville',
    52: 'Congo - Kinshasa',
    53: 'Cook Islands',
    54: 'Costa Rica',
    55: 'Croatia',
    56: 'Cuba',
    57: 'Curaçao',
    58: 'Cyprus',
    59: 'Czechia',
    60: 'Côte d’Ivoire',
    61: 'Denmark',
    62: 'Djibouti',
    63: 'Dominica',
    64: 'Dominican Republic',
    65: 'Ecuador',
    66: 'Egypt',
    67: 'El Salvador',
    68: 'Equatorial Guinea',
    69: 'Eritrea',
    70: 'Estonia',
    71: 'Eswatini',
    72: 'Ethiopia',
    73: 'Falkland Islands',
    74: 'Faroe Islands',
    75: 'Fiji',
    76: 'Finland',
    77: 'France',
    78: 'French Guiana',
    79: 'French Polynesia',
    80: 'French Southern Territories',
    81: 'Gabon',
    82: 'Gambia',
    83: 'Georgia',
    84: 'Germany',
    85: 'Ghana',
    86: 'Gibraltar',
    87: 'Greece',
    88: 'Greenland',
    89: 'Grenada',
    90: 'Guadeloupe',
    91: 'Guam',
    92: 'Guatemala',
    93: 'Guernsey',
    94: 'Guinea',
    95: 'Guinea-Bissau',
    96: 'Guyana',
    97: 'Haiti',
    98: 'Heard & McDonald Islands',
    99: 'Honduras',
    100: 'Hong Kong SAR China',
    101: 'Hungary',
    102: 'Iceland',
    103: 'India',
    104: 'Indonesia',
    105: 'Iran',
    106: 'Iraq',
    107: 'Ireland',
    108: 'Isle of Man', 
    109: 'Israel',  
    110: 'Italy',
    111: 'Jamaica',
    112: 'Japan',
    113: 'Jersey',
    114: 'Jordan',
    115: 'Kazakhstan',
    116: 'Kenya',
    117: 'Kiribati',
    118: 'Kuwait',
    119: 'Kyrgyzstan',
    120: 'Laos',
    121: 'Latvia',
    122: 'Lebanon',
    123: 'Lesotho',
    124: 'Liberia',
    125: 'Libya',
    126: 'Liechtenstein',
    127: 'Lithuania',
    128: 'Luxembourg',
    129: 'Macao SAR China',
    130: 'Madagascar',
    131: 'Malawi',
    132: 'Malaysia',
    133: 'Maldives',
    134: 'Mali',
    135: 'Malta',
    136: 'Marshall Islands',
    137: 'Martinique',
    138: 'Mauritania',
    139: 'Mauritius',
    140: 'Mayotte',
    141: 'Mexico',
    142: 'Micronesia',
    143: 'Moldova',
    144: 'Monaco',
    145: 'Mongolia',
    146: 'Montenegro',
    147: 'Montserrat',
    148: 'Morocco',
    149: 'Mozambique',
    150: 'Myanmar (Burma)',
    151: 'Namibia',
    152: 'Nauru',
    153: 'Nepal',
    154: 'Netherlands',
    155: 'New Caledonia',
    156: 'New Zealand',
    157: 'Nicaragua',
    158: 'Niger',
    159: 'Nigeria',
    160: 'Niue',
    161: 'Norfolk Island',
    162: 'Northern Mariana Islands',
    163: 'Norway',
    164: 'Oman',
    165: 'Pakistan',
    166: 'Palau',
    167: 'Palestinian Territories',
    168: 'Panama',
    169: 'Papua New Guinea',
    170: 'Paraguay',
    171: 'Peru',
    172: 'Philippines',
    173: 'Pitcairn Islands',
    174: 'Poland',
    175: 'Portugal',
    176: 'Puerto Rico',
    177: 'Qatar',
    178: 'Romania',
    179: 'Russia',
    180: 'Rwanda',
    181: 'Réunion',
    182: 'Samoa',
    183: 'San Marino',
    184: 'Saudi Arabia',
    185: 'Senegal',
    186: 'Serbia',
    187: 'Seychelles',
    188: 'Sierra Leone',
    189: 'Singapore',
    190: 'Sint Maarten',
    191: 'Slovakia',
    192: 'Slovenia',
    193: 'Solomon Islands',
    194: 'Somalia',
    195: 'South Africa',
    196: 'South Georgia & South Sandwich Islands',
    197: 'South Korea',
    198: 'South Sudan',
    199: 'Spain',
    200: 'Sri Lanka',
    201: 'St. Barthélemy',
    202: 'St. Helena',
    203: 'St. Kitts & Nevis',
    204: 'St. Lucia',
    205: 'St. Martin',
    206: 'St. Pierre & Miquelon',
    207: 'St. Vincent & Grenadines',
    208: 'Sudan',
    209: 'Suricountry_name',
    210: 'Svalbard & Jan Mayen',
    211: 'Swaziland',
    212: 'Sweden',
    213: 'Switzerland',
    214: 'Syria',
    215: 'São Tomé & Príncipe',
    216: 'Taiwan',
    217: 'Tajikistan',
    218: 'Tanzania',
    219: 'Thailand',
    220: 'Timor-Leste',
    221: 'Togo',
    222: 'Tokelau',
    223: 'Tonga',
    224: 'Trincountry_idad & Tobago',
    225: 'Tunisia',
    226: 'Turkey',
    227: 'Turkmenistan',
    228: 'Turks & Caicos Islands',
    229: 'Tuvalu',
    230: 'U.S. Outlying Islands',
    231: 'U.S. Virgin Islands',
    232: 'Uganda',
    233: 'Ukraine',
    234: 'United Arab Emirates',
    235: 'United Kingdom',
    236: 'United States',
    237: 'Uruguay',
    238: 'Uzbekistan',
    239: 'Vanuatu',
    240: 'Vatican City',
    241: 'Venezuela',
    242: 'Vietnam',
    243: 'Wallis & Futuna',
    244: 'Western Sahara',
    245: 'Yemen',
    246: 'Zambia',
    247: 'Zimbabwe'
};


