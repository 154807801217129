import React from "react";

import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

// core components


function Header() 
{

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem("authenticated");
    sessionStorage.removeItem("has_room_allocation_view");
    sessionStorage.removeItem("has_mandatory_docs_view");
    sessionStorage.removeItem("has_room_deposit_view");
    sessionStorage.removeItem("has_checkin_checklist_view");
    sessionStorage.removeItem("has_checkout_checklist_view");
    navigate("/", { replace: true });  
}


  return (
        <div id="navbar">
            <Navbar className="bg-primary" expand="lg">
              <Container>
                <div className="navbar-translate">
                  <button
                    onClick={() => {
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(!collapseOpen);
                    }}
                    aria-expanded={collapseOpen}
                    className="navbar-toggler"
                    type="button"
                  >
                    <span className="navbar-toggler-bar bar1"></span>
                    <span className="navbar-toggler-bar bar2"></span>
                    <span className="navbar-toggler-bar bar3"></span>
                  </button>
                </div>
                <Collapse isOpen={collapseOpen} navbar>
                <Nav navbar>
                      <NavItem id="navItemDashboard">
                        <NavLink onClick={(e) => navigate("/dashboard", { replace: true })}>
                            <p>Dashboard</p>
                        </NavLink>
                      </NavItem>

                      <NavItem id="navItemDocuments">
                        <NavLink onClick={(e) => navigate("/docs", { replace: true })}>
                            <p>Docs</p>
                        </NavLink>
                      </NavItem>



                      <UncontrolledDropdown nav>

                            <DropdownToggle
                              aria-haspopup={true}
                              caret
                              color="default" nav>
                              <p>Rooms</p>
                            </DropdownToggle>

                            <DropdownMenu>

                              <DropdownItem onClick={(e) => navigate("/roomRoster", { replace: true })}>
                                Rooms Roster
                              </DropdownItem>

                              <DropdownItem onClick={(e) => navigate("/resGuests", { replace: true })}>
                                Residential Guests
                              </DropdownItem>

                              <DropdownItem onClick={(e) => navigate("/nonResGuests", { replace: true })}>
                                Non-Residential Guests
                              </DropdownItem>

                              <DropdownItem onClick={(e) => navigate("/ReferenceGuests", { replace: true })}>
                                Reference Guests
                              </DropdownItem>


                            </DropdownMenu>

                      </UncontrolledDropdown>

                      <NavItem id="navItemKeyDeposit">
                        <NavLink onClick={(e) => navigate("/deposits", { replace: true })}>
                            <p>Deposits</p>
                        </NavLink>
                      </NavItem>


                      <NavItem id="navItemCheckInChecklist">
                        <NavLink onClick={(e) => navigate("/checkins", { replace: true })}>
                            <p>Check Ins</p>
                        </NavLink>
                      </NavItem>


                      <NavItem id="navItemCheckOut">
                      <NavLink onClick={(e) => navigate("/checkouts", { replace: true })}>
                          <p>Check Outs</p>
                        </NavLink>
                      </NavItem>

                      <NavItem id="navItemReports">
                      <NavLink onClick={(e) => navigate("/reports", { replace: true })}>
                          <p>Reports</p>
                        </NavLink>
                      </NavItem>

                    </Nav>


                  <Nav className="ml-auto" navbar>
                  {/* <p>Welcome, <b>Bharathi</b>!</p> */}
                    <NavItem>
                      <NavLink onClick={(e) => e.preventDefault()}>
                          <i className="now-ui-icons media-1_button-power"></i>
                          <p><a href="#" onClick={() => logout()} >Sign Off</a></p>
                        </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
          </div>
  );
}

export default Header;
