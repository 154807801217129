import React, { useState, useEffect } from 'react';

import {logError, checkError} from './utils/helpers';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import DocDetails from './DocDetails';

// core components
import Header from "./Header";
import isOnline from 'is-online';

// reactstrap components
import {
  Modal, 
  ModalBody,
  Button,
  Container
} from "reactstrap";

import { useNavigate } from "react-router-dom";


var arrDataSource = [];

const Docs = () => {

  const navigate = useNavigate();

  const [showGuestDetails, setGuestDetailsFlag] = useState(false);
  const [guestIdDetailId, setGuestIdDetailId] = useState(0);
 
  const handleOnSelect = (item) => {
    setGuestIdDetailId(item.id);  
    setGuestDetailsFlag(true);
  }

  const handleOnClear = (item) => {
    setGuestDetailsFlag(false);
  }
  
  const formatResult = (item) => {
    return (
      <div style={{ display: 'block' }}>
          <span className="result-span">{item.name}</span>
          <span className="result-type-span">{item.type_name} </span>
      </div>
    )
  }

  const [modalMD, setModalMD] = React.useState(false);

  const loadData = () => {

    if (sessionStorage.getItem('authenticated') === 'true'){

    var totalCount = 0;
    return fetch("/apiGuest")
      .then((response) => {
        totalCount = response.headers.get('X-Total-Count');
        return response.json()
      })
      .then((result) => {
        for (let i = 0; i < parseInt(totalCount); ++i) {
          arrDataSource.push({ 
            id: result[i].guest_id_detail_id, 
            name: result[i].guest_name, 
            type_name: result[i].type_name
          });
        }
        setDataSource(arrDataSource);
        return arrDataSource;
      })
    } else {
      navigate("/", { replace: true });
    }
  }

  const [dataSource, setDataSource] = useState(loadData);

  useEffect(() => {
    return () => {
      arrDataSource = [];
    };
  }, []);

        if (sessionStorage.getItem("has_mandatory_docs_view") === 'false' || sessionStorage.getItem("has_mandatory_docs_view") === '0') {
            return (
              <>
              <Header selected="Docs"/>
              <div class="divErrorMsg">Sorry, no access!</div>);
              </> 
          );
        } else {
              return (
                <>
                  <Modal
                    modalClassName="modal-mini modal-danger"
                    toggle={() => setModalMD(false)}
                    isOpen={modalMD}>
                    <div className="modal-header justify-content-center">
                      No internet!
                    </div>
                    <ModalBody>
                      <p>Oops, you are offline! Your data might not have saved.</p>
                    </ModalBody>
                  </Modal>

                  <Header selected="Docs"/>
                    <div className="wrapper">
                        <div className="section section-about-us">
                        <Container>
                                <h2 className="title">Documents Submission</h2>
                                      
                                <div className="Dashboard">
                                    <header className="Dashboard-header">
                                      <div style={{ width: 400 }}>
                                        <ReactSearchAutocomplete
                                          items={dataSource}
                                          onSelect={handleOnSelect}
                                          onClear={handleOnClear}
                                          autoFocus
                                          formatResult={formatResult}
                                          maxResults={5}
                                          placeholder = "Type the guest name here..."
                                        />
                                      </div>
                                    </header>
                                </div>

                               { showGuestDetails ? (
                                   <DocDetails gId={guestIdDetailId} /> 
                                  ): null
                                }
                        </Container>
                    </div>
                </div>
                </>
              );
        }
   }
 
 export default () => <Docs />
 