import React, {useEffect} from "react";


function FoodCounts({data, selectedDate}) {

    const currentYear = new Date().getFullYear();   

    useEffect(() => {
        printFoodCounts()
      }, []);

    const printFoodCounts = () => {
        var printWindow = window.open(' ', 'Expected Food Counts Report', 'height=600,width=800');
        printWindow.document.write('<html><head>');
        printWindow.document.write('</head><body style="font-family:verdana; font-size:14px;width:100%;">');
        printWindow.document.write(document.getElementById("divFoodCounts").innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();  
      }

  return (
    <>
      <div className="wrapper">
            <div id="divFoodCounts" style={{visibility:'hidden', display:'none'}}>
            <h4 style={{margin: 0, textAlign: 'center'}}>IYF {currentYear}</h4>
                        <h5 style={{margin: 0, textAlign: 'center'}}>PARMARTH NIKETAN, RISHIKESH</h5>
                        <h6 style={{textAlign: 'center'}}>{selectedDate} - Expected Food Counts Report</h6>

                        <table style={{borderSpacing: 0,borderCollapse: 'collapse', position: 'absolute', width: '100%', fontSize: 12}}>
                        <tbody>
                            <tr>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '1%'}}>#</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '20%'}}>Ticket Type</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Breakfast</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Lunch</td>
                                <td style={{margin: 0, padding: '1em', fontWeight: 'bold', borderTop: 'solid 1px black', borderBottom: 'solid 1px black', width: '15%'}}>Dinner</td>
                            </tr>
                            {data.map(item => (
                            <tr>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {data.findIndex(x => x.ticket_type === item.ticket_type) + 1}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.ticket_type}
                                </td>

                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black', whiteSpace: 'nowrap'}}>
                                    {item.breakfast_count}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.lunch_count}
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    {item.dinner_count}
                                </td>
                            </tr>
                            ))}
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    <b>Total:</b>
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    <b>{data.reduce((total, item) => total + item.breakfast_count, 0)}</b>
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    <b>{data.reduce((total, item) => total + item.lunch_count, 0)}</b>
                                </td>
                                <td style={{margin: 0, padding: '1em', borderBottom: 'dashed 1px black'}}>
                                    <b>{data.reduce((total, item) => total + item.dinner_count, 0)}</b>
                                </td>

                        </tbody>
                        </table>
            </div>
      </div>
    </>
  );
}

export default FoodCounts;
